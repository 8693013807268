import classNames from "components/ui/classNames";
import type { RefinementListProvided } from "react-instantsearch-core";

interface IToggableRefinementListProps {
  items: RefinementListProvided["items"];
  refine: RefinementListProvided["refine"];
}

export default function ToggableRefinementList({ items, refine }: IToggableRefinementListProps) {
  return (
    <ul className="space-y-4">
      {items.map(({ isRefined, value, label, count }) => {
        return (
          <li key={label}>
            <button
              type="button"
              onClick={() => refine(value)}
              className={classNames(
                "group",
                "m-0 cursor-pointer p-0 text-sm font-medium tracking-wider text-slate-500",
                "flex w-full items-center justify-between",
                "dark:text-slate-400",
                "dark:hover:text-slate-300",
                {
                  "font-bold text-violet-600 dark:text-violet-300": isRefined,
                },
              )}
            >
              <div className="mr-6 overflow-hidden text-ellipsis whitespace-nowrap">{label.trim()}</div>

              <div
                className={classNames(
                  "group relative text-slate-500 min-w-10 rounded-xl text-xs py-0.5",
                  "dark:bg-slate-950/50 dark:text-slate-400 bg-slate-100",
                  {
                    "text-violet-600 bg-violet-300/30": isRefined,
                    "dark:text-violet-300 dark:bg-violet-600/20": isRefined,
                  },
                )}
              >
                <span className="relative select-none font-bold">{count}</span>
              </div>
            </button>
          </li>
        );
      })}
    </ul>
  );
}
