import type { IPremiumPricesQuery } from "lib/graphql/queries/__generated__/PremiumPricesQuery.generated";
import type { IntlShape } from "react-intl";
import { IStripeRecurringPriceInterval } from "types/graphqlTypes";

export default function getPlatformPrices(intl: IntlShape, prices: NonNullable<IPremiumPricesQuery["premiumPrices"]>) {
  return [
    {
      plan: intl.formatMessage({ defaultMessage: "Gratuito", id: "AMJRek" }),
      description: intl.formatMessage({ defaultMessage: "Pra quem se vira com pouquíssimos dados...", id: "yX23NH" }),
      features: [
        intl.formatMessage({
          defaultMessage: "Acesso a mais de 6.000 ativos",
          id: "VXqHho",
        }),
        intl.formatMessage({
          defaultMessage: "Último balanço anual",
          id: "Po1v1E",
        }),
        intl.formatMessage({
          defaultMessage: "Valuation diário",
          id: "NLF0Zv",
        }),
        intl.formatMessage({
          defaultMessage: "Cotação até 5 anos",
          id: "cdN7Lj",
        }),
        intl.formatMessage({
          defaultMessage: "Negócios até 30 dias",
          id: "bdtjYF",
        }),
        intl.formatMessage({
          defaultMessage: "Gerenciador de ativos",
          id: "Qqdsda",
        }),
        intl.formatMessage({
          defaultMessage: "Email semanal dos Fatos Relevantes",
          id: "VB3Oqu",
        }),
      ],
    },
    {
      plan: "Premium",
      description: intl.formatMessage({
        defaultMessage: "Para quem quer realmente evoluir como investidor!",
        id: "hOslGL",
      }),
      price: {
        monthly: prices.find(({ recurringInterval }) => recurringInterval === IStripeRecurringPriceInterval.Month)
          ?.formattedMonthlyAmount,
        yearly: prices.find(({ recurringInterval }) => recurringInterval === IStripeRecurringPriceInterval.Year)
          ?.formattedAmount,
      },
      features: [
        intl.formatMessage({
          defaultMessage: "Histórico de dados ilimitado",
          id: "wso7wX",
        }),
        intl.formatMessage({
          defaultMessage: "Acesso a todas as ferramentas",
          id: "PlP9t8",
        }),
        intl.formatMessage({
          defaultMessage: "Acesso as discussões",
          id: "9nAVS0",
        }),
        intl.formatMessage({
          defaultMessage: "Ranking de ativos",
          id: "mhPFmD",
        }),
        intl.formatMessage({
          defaultMessage: "Tabela customizável",
          id: "gqYx+x",
        }),
        intl.formatMessage({
          defaultMessage: "KPIs de mais de 1.800 empresas",
          id: "4PBHUG",
        }),
      ],
    },
  ];
}
