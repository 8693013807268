import { useEffect, useState } from "react";

export default function useCloseTimeout(ms: number): [boolean, React.Dispatch<React.SetStateAction<boolean>>] {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  useEffect(() => {
    let lastScheduledTimeout: NodeJS.Timeout;

    if (isOpen) {
      lastScheduledTimeout = setTimeout(() => setIsOpen((prev) => !prev), ms);
    }

    return () => clearTimeout(lastScheduledTimeout);
  }, [isOpen, ms]);

  return [isOpen, setIsOpen];
}
