/* eslint-disable react-hooks/exhaustive-deps */
import { useMutation } from "@apollo/client";
import { useGTMDispatch } from "@elgorditosalsero/react-gtm-hook";
import { AuthContext } from "components/auth/Auth";
import { useAmplitude } from "lib/amplitude/Amplitude";
import { getUtmParametersFromParsedUrlQuery } from "lib/analytics/utm";
import useSelfie from "lib/hooks/useSelfie";
import SignInWithGoogleOAuthIdTokenMutation from "lib/queries/auth/SignInWithGoogleOAuthIdTokenMutation";
import type {
  ISignInWithGoogleOAuthIdTokenMutation,
  ISignInWithGoogleOAuthIdTokenMutationVariables,
} from "lib/queries/auth/__generated__/SignInWithGoogleOAuthIdTokenMutation.generated";
import isBrowser from "lib/utils/isBrowser";
import { useRouter } from "next/router";
import Script from "next/script";
import { useContext, useEffect } from "react";

interface GoogleOneTapSignInProps {
  redirectTo?:
    | string
    | {
        url: string;
        as: string;
      };
}

export default function GoogleOneTapSignIn({ redirectTo }: GoogleOneTapSignInProps) {
  const router = useRouter();
  const amplitude = useAmplitude();
  const sendDataToGTM = useGTMDispatch();
  const user = useSelfie();
  const { login } = useContext(AuthContext);

  const [signUpFromGoogleOAuthIdToken] = useMutation<
    ISignInWithGoogleOAuthIdTokenMutation,
    ISignInWithGoogleOAuthIdTokenMutationVariables
  >(SignInWithGoogleOAuthIdTokenMutation, {
    refetchQueries: [],
    onCompleted: (data) => {
      if (data && data.signInWithGoogleOAuthIdToken) {
        if (data.signInWithGoogleOAuthIdToken.isRecentlyCreatedUser) {
          amplitude.logEvent("Signed up", {
            authentication: "google",
            email: data.signInWithGoogleOAuthIdToken.email,
            ...getUtmParametersFromParsedUrlQuery(router.query),
          });

          sendDataToGTM({
            event: "signedUp",
          });
        }

        login(data.signInWithGoogleOAuthIdToken.accessToken);
        if (redirectTo && typeof redirectTo === "string") {
          router.replace(redirectTo);
        } else if (redirectTo && typeof redirectTo === "object") {
          router.replace(redirectTo.url, redirectTo.as);
        } else {
          router.replace("/");
        }

        amplitude.logEvent("Signed in", {
          method: "google",
          email: data.signInWithGoogleOAuthIdToken.email,
          ...getUtmParametersFromParsedUrlQuery(router.query),
        });
      }
    },
  });

  useEffect(() => {
    runWithGoogleAccountsId((id) => {
      if (user) {
        id.cancel();
      } else {
        id.prompt();
      }
    });

    return () => {
      runWithGoogleAccountsId((id) => {
        if (user) {
          id.cancel();
        }
      });
    };
  }, [user]);

  // See: https://developers.google.com/identity/gsi/web/guides/client-library
  return (
    <Script
      id="googleOneTap"
      async={true}
      defer={true}
      src="https://accounts.google.com/gsi/client"
      onLoad={() => {
        runWithGoogleAccountsId((id) => {
          id.initialize({
            // See: https://developers.google.com/identity/gsi/web/guides/get-google-api-clientid
            // See: https://developers.google.com/identity/gsi/web/reference/js-reference#IdConfiguration
            client_id: process.env.GOOGLE_CLIENT_ID,
            cancel_on_tap_outside: false,
            use_fedcm_for_prompt: true,
            auto_select: false,
            context: "signin",
            callback: ({ credential }) => {
              signUpFromGoogleOAuthIdToken({
                variables: {
                  idToken: credential,
                  utms: {
                    utmSource: null,
                    utmMedium: null,
                    utmCampaign: null,
                    utmTerm: null,
                    utmContent: null,
                    ...getUtmParametersFromParsedUrlQuery(router.query),
                  },
                },
              });
            },
          });

          id.prompt();
        });
      }}
    />
  );
}

export function runWithGoogleAccountsId(fn: (id: GoogleAccountsId) => void) {
  if (
    isBrowser() &&
    window &&
    window.google &&
    window.google.accounts &&
    window.google.accounts.id &&
    typeof window.google.accounts.id.initialize === "function" &&
    typeof window.google.accounts.id.prompt === "function"
  ) {
    fn(window.google.accounts.id);
  }
}

type PromptMomentNotification = {
  isSkippedMoment: () => boolean;
  isDismissedMoment: () => boolean;
  getDismissedReason: () => "credential_returned" | "cancel_called" | "flow_restarted";
  getMomentType: () => "display" | "skipped" | "dismissed";
};

type CredentialResponse = {
  // This field is the ID token as a base64-encoded JSON Web Token (JWT) string.
  credential: string;
  // This field sets how the credential is selected.
  select_by: string;
};

// See: https://developers.google.com/identity/gsi/web/reference/js-reference#IdConfiguration
type GoogleOneTapIdConfiguration = {
  // Your application's client ID.
  client_id?: string;
  // Enables automatic selection.
  auto_select?: boolean;
  // The JavaScript function that handles ID tokens. Google One Tap and the Sign In With Google button popup UX mode
  // use this attribute.
  callback?: (response: CredentialResponse) => void;
  // The URL of your login endpoint. The Sign In With Google button redirect UX mode uses this attribute.
  login_uri?: string;
  // The JavaScript function that handles password credentials.
  native_callback?: () => void;
  // Cancels the prompt if the user clicks outside the prompt.
  cancel_on_tap_outside?: boolean;
  // The DOM ID of the One Tap prompt container element.
  prompt_parent_id?: string;
  // A random string for ID tokens.
  nonce?: string;
  // The title and words in the One Tap prompt.
  context?: "use" | "signin" | "signup";
  // If you need to call One Tap in the parent domain and its subdomains, pass the parent domain to this field
  // so that a single shared cookie is used.
  state_cookie_domain?: string;
  // The Sign In With Google button UX flow
  ux_mode?: "redirect" | "popup";
  // The origins that are allowed to embed the intermediate iframe. One Tap will run in the intermediate
  // iframe mode if this field presents.
  allowed_parent_origin?: string | string[];
  // Overrides the default intermediate iframe behavior when users manually close One Tap.
  intermediate_iframe_close_callback?: () => void;
  // Allow the browser to control user sign-in prompts and mediate the sign-in flow between your website and Google.
  use_fedcm_for_prompt?: boolean;
};

type GoogleAccountsId = {
  initialize: (idConfiguration: GoogleOneTapIdConfiguration) => void;
  prompt: (listener?: (notification: PromptMomentNotification) => void) => void;
  cancel: () => void;
  disableAutoSelect: () => void;
  storeCredential: (credential?: string, callback?: () => void) => void;
  onGoogleLibraryLoad: () => void;
};

declare global {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  interface Window {
    google?: {
      accounts?: {
        id?: GoogleAccountsId;
      };
    };
  }
}
