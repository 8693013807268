import classNames from "components/ui/classNames";
import getBaseUrl from "lib/utils/getBaseUrl";
import type { IntlShape } from "react-intl";
import { format } from "util";

export default function getAssetTypeTabs(intl: IntlShape) {
  if (intl.locale.startsWith("pt-BR")) {
    return [
      {
        title: intl.formatMessage({ defaultMessage: "Ações", id: "FLGl5o" }),
        description: intl.formatMessage({
          defaultMessage: "Mais de 300 ativos disponíveis para você analisar",
          id: "3lFdjl",
        }),
        value: "ACOES",
        content: <Screenshot src={format("%s/static/landing/images/pt/acoes.png", getBaseUrl())} alt="Ações" />,
      },
      {
        title: intl.formatMessage({ defaultMessage: "FIIs", id: "f9DMbU" }),
        description: intl.formatMessage({
          defaultMessage: "Cerca de 400 FIIs e Fiagros listados para análise",
          id: "CZbtAY",
        }),
        value: "FIIs",
        content: <Screenshot src={format("%s/static/landing/images/pt/fiis.png", getBaseUrl())} alt="FIIs" />,
      },
      {
        title: intl.formatMessage({ defaultMessage: "Stocks", id: "6rgkM5" }),
        description: intl.formatMessage({
          defaultMessage: "Mais de 5500 Stocks disponíveis para análise (NYSE, Nasdaq)",
          id: "rsNxiV",
        }),
        value: "STOCKS",
        content: <Screenshot src={format("%s/static/landing/images/pt/stocks.png", getBaseUrl())} alt="Stocks" />,
      },
      {
        title: intl.formatMessage({ defaultMessage: "REITs", id: "QOIFTQ" }),
        description: intl.formatMessage({ defaultMessage: "Mais de 190 REITs disponíveis para análise", id: "efR1+S" }),
        value: "REITS",
        content: <Screenshot src={format("%s/static/landing/images/pt/reits.png", getBaseUrl())} alt="REITs" />,
      },
    ];
  }
  return [
    {
      title: intl.formatMessage({ defaultMessage: "Stocks", id: "6rgkM5" }),
      description: intl.formatMessage({
        defaultMessage: "Mais de 5500 Stocks disponíveis para análise (NYSE, Nasdaq)",
        id: "rsNxiV",
      }),
      value: "STOCKS",
      content: <Screenshot src={format("%s/static/landing/images/en/stocks.png", getBaseUrl())} alt="Stocks" />,
    },
    {
      title: intl.formatMessage({ defaultMessage: "REITs", id: "QOIFTQ" }),
      description: intl.formatMessage({ defaultMessage: "Mais de 190 REITs disponíveis para análise", id: "efR1+S" }),
      value: "REITS",
      content: <Screenshot src={format("%s/static/landing/images/en/reits.png", getBaseUrl())} alt="REITs" />,
    },
  ];
}

interface ScreenshotProps {
  src: string;
  alt: string;
}

function Screenshot({ src, alt }: ScreenshotProps) {
  return (
    <div
      className={classNames("relative mx-auto flex max-w-3xl justify-center rounded-xl text-center shadow-slate-400")}
    >
      <img src={src} width={672} height={476} alt={alt} className="pointer-events-none w-full object-cover" />
    </div>
  );
}
