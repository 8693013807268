import { Checkbox } from "lib/miolo";
import type { ICheckboxProps } from "lib/miolo/input/Checkbox";
import { connectToggleRefinement } from "react-instantsearch-dom";

interface IConnectedToggleProps extends ICheckboxProps {
  label: string;
  currentRefinement: boolean;
  refine: (nextRefinement: boolean) => boolean;
}

function ConnectedToggle({ id, name, className, label, currentRefinement, refine }: IConnectedToggleProps) {
  return (
    <Checkbox
      id={id}
      name={label || name}
      className={className}
      checked={currentRefinement}
      onChange={(isChecked) => refine(isChecked)}
    />
  );
}

export default connectToggleRefinement(ConnectedToggle);
