import GoogleOneTapSignIn from "components/auth/GoogleOneTapSignIn";
import Footer from "components/landing/Footer";
import Header from "components/landing/Header";
import SignUpNow from "components/landing/SignUpNow";
import AboutUs from "components/landing/sections/AboutUs";
import AlternativeFeatureCards from "components/landing/sections/AlternativeFeatureCards";
import PlatformAssetTypeSection from "components/landing/sections/PlatformAssetTypeSection";
import PlatformFeaturesSection from "components/landing/sections/PlatformFeaturesSection";
import PlatformMetricsSection from "components/landing/sections/PlatformMetricsSection";
import PlatformPricesSection from "components/landing/sections/PlatformPricesSection";
import Quote from "components/landing/sections/Quote";
import TestimonialsSection from "components/landing/sections/TestimonialsSection";
import TwoPassRender from "components/shared/TwoPassRender";
import { useIntl } from "react-intl";

export default function Landing() {
  const intl = useIntl();
  return (
    <main className="mx-auto overflow-x-hidden scroll-smooth bg-white">
      <GoogleOneTapSignIn />

      <Header />
      <PlatformMetricsSection />
      <PlatformAssetTypeSection />
      <AlternativeFeatureCards />
      <PlatformFeaturesSection />
      <TwoPassRender>{() => <PlatformPricesSection />}</TwoPassRender>
      <TestimonialsSection />
      <AboutUs />
      {intl.locale !== "pt-BR" && <Quote />}
      <SignUpNow />
      <Footer />
    </main>
  );
}
