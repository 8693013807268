import ToggableRefinementList from "components/screener/refinements/ToggableRefinementList";
import useInfinitePagination from "lib/hooks/useInfinitePagination";
import { MiniSearchInput } from "lib/miolo";
import deburr from "lodash/fp/deburr";
import orderBy from "lodash/fp/orderBy";
import { useEffect, useState } from "react";
import type { RefinementListProvided } from "react-instantsearch-core";
import { connectRefinementList } from "react-instantsearch-dom";
import { FormattedMessage, useIntl } from "react-intl";

const RealEstateFundAdministratorRefinementList = connectRefinementList<RefinementListProvided>(({ items, refine }) => {
  const intl = useIntl();
  const administrators = orderBy(["isRefined", "count", "label"], ["desc", "desc", "asc"], items);

  const [searchQuery, setSearchQuery] = useState<string>("");

  const pagination = useInfinitePagination({
    itemsPerPage: 5,
    items: administrators.filter((item) => {
      if (searchQuery && searchQuery.trim()) {
        const cleanSearchQuery = searchQuery.trim();
        return deburr(item.label).toLowerCase().includes(deburr(cleanSearchQuery).toLowerCase());
      }

      return true;
    }),
  });

  useEffect(() => {
    if (searchQuery !== "") {
      pagination.reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery]);

  return (
    <div className="space-y-4 font-aeonik">
      <div className="space-y-1">
        <h3 className="font-aeonik text-base font-bold uppercase tracking-wide text-zinc-500">
          <FormattedMessage defaultMessage="Administrator" id="3wmuyF" />
        </h3>
        <span className="flex font-aeonik text-sm font-normal italic tracking-wide text-slate-400">
          <FormattedMessage defaultMessage="Filtre por administradores." id="RD5IR2" />
        </span>
      </div>

      <MiniSearchInput
        value={searchQuery}
        placeholder={intl.formatMessage({
          defaultMessage: "Pesquisar",
          id: "keT/wF",
        })}
        onChange={(searchQuery) => setSearchQuery(searchQuery)}
      />

      {pagination.items.length > 0 && (
        <>
          <ToggableRefinementList items={pagination.items} refine={refine} />

          {pagination.hasMoreToShow && (
            <div className="text-center">
              <button
                type="button"
                onClick={pagination.showMore}
                className="font-aeonik text-sm font-medium tracking-wide text-sky-600 hover:underline"
              >
                <FormattedMessage defaultMessage="Ver mais administradores" id="j7H3Tk" />
              </button>
            </div>
          )}
        </>
      )}
    </div>
  );
});

export default function RealEstateFundAdministrator() {
  return <RealEstateFundAdministratorRefinementList attribute="administrator" limit={120} />;
}
