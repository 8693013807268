/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable jsx-a11y/accessible-emoji */
import { FundamenteiBlackSignature } from "components/branding/FundamenteiBlack";
import classNames from "components/ui/classNames";
import { useAmplitude } from "lib/amplitude/Amplitude";
import getBaseUrl from "lib/utils/getBaseUrl";
import { XIcon } from "lucide-react";
import type { ReactNode } from "react";
import { useEffect } from "react";

interface FundamenteiBlackPerpetualPopUpProps extends React.HTMLAttributes<HTMLDivElement> {
  externalLinkHref: string;
  children: ReactNode;
  onClose: () => void;
}

export default function FundamenteiBlackPerpetualPopUp({
  externalLinkHref,
  children,
  onClose,
  className,
  ...props
}: FundamenteiBlackPerpetualPopUpProps) {
  const amplitude = useAmplitude();
  useEffect(() => {
    amplitude.logEvent("Viewed Black offer", {
      origin: "One-off popup",
    });
  }, []);
  return (
    <div className="px-6 xl:p-0">
      <div
        className={classNames(
          "relative rounded-2xl border border-transparent bg-gradient-to-r from-slate-600/100 to-slate-900 px-6 py-6 shadow",
          "dark:border-slate-950 dark:from-slate-800/100 dark:to-slate-950/70",
          className,
        )}
        {...props}
      >
        <div className="relative space-y-4">
          <h2 className="inline-flex items-center space-x-2 rounded-xl bg-black/20 px-2 py-1 font-bold leading-6 text-white md:text-xl md:leading-normal">
            Fundamentei Black
          </h2>

          <div className="flex flex-col items-center justify-between space-y-4 sm:flex-row sm:space-x-8 sm:space-y-0">
            <div className="text-sm font-normal leading-6 tracking-wide text-white md:text-base">{children}</div>

            <a
              href={externalLinkHref}
              target="_blank"
              rel="noopener"
              onClick={() => {
                amplitude.logEvent("Click on link to become Black", {
                  origin: "One-off popup",
                });
              }}
              className={classNames(
                "hidden w-full items-center justify-center whitespace-nowrap rounded-xl bg-orange-600/20 px-12 py-3 text-xs lg:flex",
                "font-semibold uppercase tracking-wider text-orange-300 shadow-sm md:w-auto md:text-xs",
                "hover:border-solid hover:bg-orange-600 hover:text-white hover:drop-shadow-sm",
              )}
            >
              Conferir
            </a>
          </div>

          <div className="flex flex-wrap items-start gap-2 gap-y-6 sm:flex-nowrap sm:items-center lg:justify-between lg:gap-y-4">
            <div className="flex flex-nowrap items-center space-x-2">
              <img
                src={`${getBaseUrl()}/static/people/eduardo-cavalcanti.png`}
                alt="Eduardo Cavalcanti"
                className="size-10 rounded-3xl squircle md:h-12 md:w-12"
                width={48}
                height={48}
              />

              <div className="text-white">
                <h3 className="text-base font-semibold drop-shadow-2xl md:text-sm">Eduardo Cavalcanti</h3>
                <p className="text-xs font-normal italic tracking-wide text-gray-200">
                  CEO & Cofundador do Fundamentei
                </p>
              </div>
            </div>

            <div className="ml-auto flex w-full justify-center space-x-4 sm:w-auto">
              <FundamenteiBlackSignature fill="white" className="inline-flex" />
            </div>
          </div>

          <a
            href={externalLinkHref}
            target="_blank"
            rel="noopener noreferrer"
            className={classNames(
              "flex w-full items-center justify-center whitespace-nowrap rounded-xl bg-orange-600/20 px-12 py-3 text-xs lg:hidden",
              "font-semibold uppercase tracking-wider text-orange-300 shadow-sm md:w-auto md:text-xs",
              "hover:border-solid hover:bg-orange-600 hover:text-white hover:drop-shadow-sm",
            )}
            onClick={() => {
              amplitude.logEvent("Click on link to become Black", {
                origin: "One-off popup",
              });
            }}
          >
            Conferir
          </a>
        </div>

        <div className="absolute right-0 top-0 -m-3">
          <button
            type="button"
            aria-label="Fechar"
            onClick={() => {
              onClose();
              amplitude.logEvent("Dismissed Black offer", {
                origin: "One-off popup",
              });
            }}
            className="cursor-pointer rounded-full bg-red-500 p-1 text-white shadow-lg hover:bg-red-400 dark:bg-red-600"
          >
            <XIcon className="size-4" strokeWidth={3} />
          </button>
        </div>
      </div>
    </div>
  );
}
