import useLandingContents from "components/landing/hooks/useLandingContents";
import classNames from "components/ui/classNames";
import getBaseUrl from "lib/utils/getBaseUrl";
import CountUp from "react-countup";
import { FormattedMessage, useIntl } from "react-intl";
import { format } from "util";

export default function PlatformMetricsSection() {
  const intl = useIntl();
  const { platformMetrics, isUsLanguage } = useLandingContents();

  return (
    <section className="mx-auto flex max-w-7xl flex-col items-center px-6 pb-16 lg:px-0">
      <header className="mx-auto flex max-w-3xl flex-col gap-4">
        <h1 className="text-center text-3xl font-black lg:text-5xl">
          <FormattedMessage defaultMessage="Análise de Ações, Fundos Imobiliários, Stocks e REITs" id="rqcjGp" />
        </h1>

        <p className="text-center text-lg text-zinc-600">
          <FormattedMessage
            defaultMessage="O melhor site de Buy&Hold do Brasil com acesso imediato a mais de 6.000 ativos!"
            id="NMfQgR"
          />
        </p>
      </header>

      <div className="rounded-xl p-4 py-12 lg:h-[628px] lg:py-4">
        <video
          muted={true}
          autoPlay={true}
          loop={true}
          playsInline={true}
          className={classNames(
            "pointer-events-none w-full max-w-6xl overflow-hidden rounded-3xl bg-transparent object-cover",
            "scale-125 md:scale-100 lg:h-[628px]",
          )}
        >
          <source
            type="video/mp4"
            src={format(
              "%s/static/landing/video/%s/fundamentals.mp4",
              getBaseUrl(),
              intl.locale === "pt-BR" ? "pt" : "en",
            )}
          />
        </video>
      </div>

      <div className="grid items-center gap-12 lg:grid-cols-4 lg:gap-0 lg:divide-x lg:divide-slate-100">
        {(isUsLanguage ? platformMetrics.US : platformMetrics.BR).map(({ indicator, value, ...rest }) => (
          <div key={indicator} className="flex flex-col gap-2 px-5 text-center lg:mt-4">
            <h4 className="text-2xl font-bold lg:text-4xl">
              <CountUp end={value} duration={2.75} enableScrollSpy={false} {...rest} />
            </h4>

            <p className="text-sm text-zinc-500">{indicator}</p>
          </div>
        ))}
      </div>
    </section>
  );
}
