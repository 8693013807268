import { RangeSlider, palette } from "lib/miolo";
import { useState } from "react";
import { connectRange } from "react-instantsearch-core";
import formatMoneyValue from "components/utils/formatMoneyValue";

type RefineArguments = {
  min?: number;
  max?: number;
};

interface ConnectedMoneySliderMarksRangeProps {
  label: string;
  accessibilityLabels: [string, string];
  refine: (refinement: RefineArguments) => void;
  min?: number;
  max?: number;
  currentRefinement: {
    min?: number;
    max?: number;
  };
}

function ConnectedMoneySliderMarksRange({
  label,
  accessibilityLabels,
  refine,
  currentRefinement,
  min,
  max,
}: ConnectedMoneySliderMarksRangeProps) {
  const [willUpdateToValues, setWillUpdateToValues] = useState<ReadonlyArray<number> | null>(null);
  const canFilter = min !== undefined && max !== undefined && min < max;

  const valueFormatter = new Intl.NumberFormat("en", {
    notation: "compact",
    minimumFractionDigits: 0,
    maximumFractionDigits: 1,
  });

  const options = [
    {
      value: min || 0,
      label: valueFormatter.format(min || 0),
    },
    {
      value: 1000000,
      label: "1M",
    },
    {
      value: 10000000,
      label: "10M",
    },
    {
      value: 100000000,
      label: "100M",
    },
    {
      value: 1000000000,
      label: "1B",
    },
    {
      value: 10000000000,
      label: "10B",
    },
    {
      value: 100000000000,
      label: "100B",
    },
    {
      value: 1000000000000,
      label: "1T",
    },
    {
      value: max || 0,
      label: valueFormatter.format(max || 0),
    },
  ];

  const presetTrack = options.filter((option) => option.value <= (max || 0) && option.value >= (min || 0));

  return (
    <div className="grid gap-y-5">
      <div className="flex justify-between text-sm tracking-wider">
        <strong className="font-bold dark:text-slate-300 text-blue-600">{label}</strong>

        {willUpdateToValues ? (
          <strong
            className="font-bold text-blue-600 dark:text-slate-300"
            style={{
              color: palette.baliHai,
            }}
          >
            {formatMoneyValue(willUpdateToValues[0] || 0, "short")} —{" "}
            {formatMoneyValue(willUpdateToValues[1] || 0, "short")}
          </strong>
        ) : (
          <strong className="font-bold text-blue-600 dark:text-slate-300">
            {formatMoneyValue(currentRefinement.min || 0, "short")} —{" "}
            {formatMoneyValue(currentRefinement.max || 0, "short")}
          </strong>
        )}
      </div>

      {canFilter && (
        <RangeSlider
          disabled={!canFilter}
          domain={[0, presetTrack.length - 1]}
          values={[0, presetTrack.length - 1]}
          labels={accessibilityLabels}
          tickValues={presetTrack.map((_, index) => index)}
          formatTick={(index) => {
            if (index <= presetTrack.length) {
              return presetTrack[index].label;
            }

            return "";
          }}
          onUpdate={([minMark, maxMark]) => {
            const min = presetTrack[minMark].value;
            const max = presetTrack[maxMark].value;

            setWillUpdateToValues([min, max]);
          }}
          onChange={([minMark, maxMark]) => {
            setWillUpdateToValues(null);

            const min = presetTrack[minMark].value;
            const max = presetTrack[maxMark].value;

            // eslint-disable-next-line no-restricted-globals
            if (isNaN(min) || isNaN(max)) {
              refine({
                min: undefined,
                max: undefined,
              });
              return;
            }

            refine({
              min,
              max,
            });
          }}
        />
      )}

      {!canFilter && max !== undefined && (
        <RangeSlider
          disabled={true}
          domain={[0, max || 0]}
          values={[0, max || 0]}
          labels={accessibilityLabels}
          onUpdate={() => {
            // ...
          }}
          onChange={() => {
            // ...
          }}
        />
      )}
    </div>
  );
}

export default connectRange(ConnectedMoneySliderMarksRange);
