import gql from "graphql-tag";
import TrailingReturnsFields from "lib/graphql/fragments/TrailingReturnsFields";

export default gql`
  query Agenda(
    $filterByStartDate: Date!
    $filterByEndDate: Date!
    $filterByIssuerIds: [ObjectID!]
    $filterByEventType: [AgendaEventType!]
    $filterByIssuerType: IssuerType
    $filterByIssuersInPortfolio: Boolean
    $filterByIssuersInBookmarks: Boolean
    $filterBySearchQuery: String
    $filterByIsInSnP500: Boolean
    $direction: OrderDirection!
    $limit: Int
    $cursor: Cursor
  ) {
    agenda(
      filterByStartDate: $filterByStartDate
      filterByEndDate: $filterByEndDate
      filterByIssuerIds: $filterByIssuerIds
      filterByEventType: $filterByEventType
      filterByIssuerType: $filterByIssuerType
      filterByIssuersInPortfolio: $filterByIssuersInPortfolio
      filterByIssuersInBookmarks: $filterByIssuersInBookmarks
      filterBySearchQuery: $filterBySearchQuery
      filterByIsInSnP500: $filterByIsInSnP500
      direction: $direction
      limit: $limit
      cursor: $cursor
    ) {
      nodes {
        _id
        eventType
        eventDate
        issuer {
          ... on AmericanCompanyIssuer {
            _id
            name
            tickerSymbolPrefix
            standardLogo {
              url
            }
            niceLogo {
              url
            }
            securities {
              trailingReturns {
                ...TrailingReturnsFields
              }
            }
          }
          ... on BrazilianCompanyIssuer {
            _id
            name
            tickerSymbolPrefix
            standardLogo {
              url
            }
            niceLogo {
              url
            }
            securities {
              trailingReturns {
                ...TrailingReturnsFields
              }
            }
          }
          ... on BrazilianRealEstateFundIssuer {
            _id
            name
            tickerSymbolPrefix
            standardLogo {
              url
            }
            trailingReturns {
              ...TrailingReturnsFields
            }
          }
        }
        ... on StandardEvent {
          eventDate
          eventFiscalYear
        }
        ... on EarningsResultsEvent {
          eventDate
          eventFiscalYear
          areFinancialsUpdated
        }
        ... on PaymentOfDividendsEvent {
          excludingDate
          recordDate
          payDate
          amountPaid
          declarationDate
          symbol
        }
        ... on PaymentOfStockDividendsEvent {
          excludingDate
          symbol
          sharePaid
        }
        ... on StockSplitEvent {
          excludingDate
          splitFrom
          splitTo
        }
        ... on RightsIssueEvent {
          excludingDate
          recordDate
          payDate
          newShare
          subscriptionPrice
        }
        ... on SpinOffEvent {
          excludingDate
          spinFrom
          spinTo
          numberOfShares
          childCompanyName
          childCompanyExchangeId
          childCompanySymbol
        }
      }
      totalCount
      nextUpcomingEventDateRange {
        filterByStartDate
        filterByEndDate
      }
      breakdown {
        today
        inTheWeek
        inTheMonth
        inTheLastMonth
        inTheNextMonth
        inTheNextNinetyDays
        inTheYear
      }
      pageInfo {
        cursor
        hasNextPage
      }
    }
  }

  ${TrailingReturnsFields}
`;
