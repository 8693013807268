import classNames from "components/ui/classNames";
import { BarChart3Icon, CircleDollarSignIcon, HandCoinsIcon, LineChartIcon, StarsIcon } from "lucide-react";
import { FormattedMessage, useIntl } from "react-intl";

export default function AlternativeFeatureCards() {
  const intl = useIntl();
  const isUsLanguage = !intl.locale.startsWith("pt");
  return (
    <section className="mx-auto w-full bg-slate-50">
      <div className="mx-auto max-w-7xl px-6 py-20 pb-32 xl:px-0">
        <header className="text-center">
          <h2 className="font-mono text-xs/5 font-semibold uppercase tracking-widest text-gray-500 data-[dark]:text-gray-400">
            <FormattedMessage defaultMessage="Dados Selecionados" id="oV8RWJ" />
          </h2>
          <h3 className="mt-2 inline-flex max-w-5xl text-balance text-3xl font-medium tracking-tighter text-black sm:text-5xl">
            <FormattedMessage
              defaultMessage="Não perca seu tempo atrás de balanços e outros dados, nós já fizemos isso por você!"
              id="2p4bl+"
            />
          </h3>
        </header>

        <div className="mt-8 grid grid-cols-1 gap-4 sm:mt-16 md:grid-cols-2 lg:grid-cols-6 lg:grid-rows-2">
          {/* Ranking */}
          <div
            className={classNames(
              "relative flex flex-col overflow-hidden rounded-xl bg-white shadow-sm ring-1 ring-black/5",
              "max-lg:rounded-t-3xl",
              "lg:col-span-3 lg:rounded-tl-3xl",
              "group",
              "transition-shadow hover:shadow-xl hover:shadow-slate-200",
            )}
          >
            {/* Embellishment */}
            <div className="absolute -bottom-10 -right-10 z-10 flex size-24 items-center justify-center rounded-full bg-orange-600/10">
              <StarsIcon
                className="-ml-8 -mt-8 inline-flex size-6 fill-orange-600 stroke-transparent"
                absoluteStrokeWidth={true}
                strokeWidth={1.5}
              />
              <div className="absolute inset-0 bg-gradient-to-t from-white to-50% group-data-[dark]:from-gray-800 group-data-[dark]:from-[-25%]" />
            </div>

            <div className="relative h-80 shrink-0">
              {isUsLanguage ? (
                <div className="h-80 bg-[url(/static/landing/screenshots/en/ranking-v3.png)] bg-[size:1081px_527px] bg-[left_24px_top_24px] bg-no-repeat" />
              ) : (
                <div className="h-80 bg-[url(/static/landing/screenshots/pt/ranking-v3.png)] bg-[size:1081px_527px] bg-[left_24px_top_24px] bg-no-repeat" />
              )}
              <div className="absolute inset-0 bg-gradient-to-t from-white to-50% group-data-[dark]:from-gray-800 group-data-[dark]:from-[-25%]" />
            </div>
            <div className="relative p-10">
              <h3 className="font-mono text-xs/5 font-semibold uppercase tracking-widest text-gray-500 data-[dark]:text-gray-400">
                <FormattedMessage defaultMessage="Os melhores ativos" id="HmQMUq" />
              </h3>
              <p className="mt-1 text-2xl/8 font-bold tracking-tight text-slate-950">
                <FormattedMessage defaultMessage="Ranking" id="YJ60t1" />
              </p>
              <p className="mt-2 max-w-[600px] text-sm/6 tracking-wide text-slate-600">
                <FormattedMessage
                  defaultMessage="Ranking completo de Ações, Fundos Imobiliários, Stocks e REITs com a média das notas de milhares de usuários!"
                  id="TgTKHK"
                />
              </p>
            </div>
          </div>
          {/* Lucro vs Cotação */}
          <div
            className={classNames(
              "relative flex flex-col overflow-hidden rounded-lg bg-white shadow-sm ring-1 ring-black/5",
              "data-[dark]:bg-gray-800 data-[dark]:ring-white/15",
              "lg:col-span-3 lg:rounded-tr-3xl",
              "group",
              "transition-shadow hover:shadow-xl hover:shadow-slate-200",
            )}
          >
            {/* Embellishment */}
            <div className="absolute -bottom-10 -right-10 z-10 flex size-24 items-center justify-center rounded-full bg-emerald-500/10">
              <LineChartIcon
                className="-ml-8 -mt-8 inline-flex size-6 stroke-emerald-600"
                absoluteStrokeWidth={true}
                strokeWidth={1.5}
              />
              <div className="absolute inset-0 bg-gradient-to-t from-white to-50% group-data-[dark]:from-gray-800 group-data-[dark]:from-[-25%]" />
            </div>
            <div className="relative h-80 shrink-0">
              {isUsLanguage ? (
                <div className="absolute inset-0 bg-[url(/static/landing/screenshots/en/net-income-vs-price-v3.png)] bg-[size:640px_323px] bg-[left_32px_top_32px] bg-no-repeat" />
              ) : (
                <div className="absolute inset-0 bg-[url(/static/landing/screenshots/pt/net-income-vs-price-v3.png)] bg-[size:640px_323px] bg-[left_32px_top_32px] bg-no-repeat" />
              )}
              <div className="absolute inset-0 bg-gradient-to-t from-white to-50% group-data-[dark]:from-gray-800 group-data-[dark]:from-[-25%]" />
            </div>
            <div className="relative p-10">
              <h3 className="font-mono text-xs/5 font-semibold uppercase tracking-widest text-gray-500 data-[dark]:text-gray-400">
                <FormattedMessage defaultMessage="Análise" id="WN8ofq" />
              </h3>
              <p className="mt-1 text-2xl/8 font-bold tracking-tight text-slate-950">
                <FormattedMessage defaultMessage="Lucro vs Cotação" id="OFLFJU" />
              </p>
              <p className="mt-2 max-w-[600px] text-sm/6 text-slate-600">
                <FormattedMessage
                  defaultMessage="Será que a cotação segue o lucro das empresas no longo prazo? Confira no gráfico!"
                  id="PCS69i"
                />
              </p>
            </div>
          </div>
          {/* Fontes de Receita */}
          <div
            className={classNames(
              "relative flex flex-col overflow-hidden rounded-lg bg-white shadow-sm ring-1 ring-black/5",
              "data-[dark]:bg-gray-800 data-[dark]:ring-white/15",
              "lg:col-span-2 lg:rounded-bl-3xl",
              "group",
              "transition-shadow hover:shadow-xl hover:shadow-slate-200",
            )}
          >
            {/* Embellishment */}
            <div className="absolute -bottom-10 -right-10 z-10 flex size-24 items-center justify-center rounded-full bg-slate-500/10">
              <BarChart3Icon
                className="-ml-8 -mt-8 inline-flex size-6 stroke-slate-600"
                absoluteStrokeWidth={true}
                strokeWidth={1.5}
              />
              <div className="absolute inset-0 bg-gradient-to-t from-white to-50% group-data-[dark]:from-gray-800 group-data-[dark]:from-[-25%]" />
            </div>
            <div className="relative h-80 shrink-0">
              {isUsLanguage ? (
                <div className="absolute inset-0 bg-[url(/static/landing/screenshots/en/revenue-segments-v4.png)] bg-[size:665px_260px] bg-[left_24px_top_32px] bg-no-repeat" />
              ) : (
                <div className="absolute inset-0 bg-[url(/static/landing/screenshots/pt/revenue-segments-v3.png)] bg-[size:464px_228px] bg-[left_32px_top_32px] bg-no-repeat" />
              )}
              <div className="absolute inset-0 bg-gradient-to-t from-white to-50% group-data-[dark]:from-gray-800 group-data-[dark]:from-[-25%]" />
            </div>
            <div className="relative p-10">
              <h3 className="font-mono text-xs/5 font-semibold uppercase tracking-widest text-gray-500 data-[dark]:text-gray-400">
                <FormattedMessage defaultMessage="Aprofunde" id="73lp6t" />
              </h3>
              <p className="mt-1 text-2xl/8 font-bold tracking-tight text-gray-950 group-data-[dark]:text-white">
                <FormattedMessage defaultMessage="Fontes de Receita" id="en0mb1" />
              </p>
              <p className="mt-2 max-w-[600px] text-sm/6 text-gray-600 group-data-[dark]:text-gray-400">
                <FormattedMessage
                  defaultMessage="Descubra com 1 clique como a empresa ganha dinheiro e também em quais países ela opera!"
                  id="5p8Pa2"
                />
              </p>
            </div>
          </div>
          {/* Valuation */}
          <div
            className={classNames(
              "relative flex flex-col overflow-hidden rounded-lg bg-white shadow-sm ring-1 ring-black/5",
              "data-[dark]:bg-gray-800 data-[dark]:ring-white/15",
              "lg:col-span-2",
              "group",
              "transition-shadow hover:shadow-xl hover:shadow-slate-200",
            )}
          >
            {/* Embellishment */}
            <div className="absolute -bottom-10 -right-10 z-10 flex size-24 items-center justify-center rounded-full bg-violet-500/10">
              <CircleDollarSignIcon
                className="-ml-8 -mt-8 inline-flex size-6 stroke-violet-600"
                absoluteStrokeWidth={true}
                strokeWidth={1.5}
              />
              <div className="absolute inset-0 bg-gradient-to-t from-white to-50% group-data-[dark]:from-gray-800 group-data-[dark]:from-[-25%]" />
            </div>
            <div className="relative h-80 shrink-0">
              {isUsLanguage ? (
                <div className="absolute inset-0 bg-[url(/static/landing/screenshots/en/valuation-v3.png)] bg-cover bg-[left_24px_top_8px] bg-no-repeat" />
              ) : (
                <div className="absolute inset-0 bg-[url(/static/landing/screenshots/pt/valuation-v3.png)] bg-cover bg-[left_24px_top_8px] bg-no-repeat" />
              )}
              <div className="absolute inset-0 bg-gradient-to-t from-white to-50% group-data-[dark]:from-gray-800 group-data-[dark]:from-[-25%]" />
            </div>
            <div className="relative p-10">
              <h3 className="font-mono text-xs/5 font-semibold uppercase tracking-widest text-gray-500 data-[dark]:text-gray-400">
                <FormattedMessage defaultMessage="Números" id="/Tbyxf" />
              </h3>
              <p className="mt-1 text-2xl/8 font-bold tracking-tight text-gray-950 group-data-[dark]:text-white">
                <FormattedMessage defaultMessage="Valuation" id="qM+1zP" />
              </p>
              <p className="mt-2 max-w-[600px] text-sm/6 text-gray-600 group-data-[dark]:text-gray-400">
                <FormattedMessage
                  defaultMessage="Dezenas de indicadores com mais de 10 anos de dados históricos como P/L, P/VPA, P/EBITDA, P/FCL, entre outros!"
                  id="gFffMl"
                />
              </p>
            </div>
          </div>
          {/* Dividendos */}
          <div
            className={classNames(
              "relative flex flex-col overflow-hidden rounded-lg bg-white shadow-sm ring-1 ring-black/5",
              "data-[dark]:bg-gray-800 data-[dark]:ring-white/15",
              "lg:col-span-2 lg:rounded-br-3xl",
              "group",
              "transition-shadow hover:shadow-xl hover:shadow-slate-200",
            )}
          >
            {/* Embellishment */}
            <div className="absolute -bottom-10 -right-10 z-10 flex size-24 items-center justify-center rounded-full bg-blue-500/10">
              <HandCoinsIcon
                className="-ml-8 -mt-8 inline-flex size-6 stroke-blue-600"
                absoluteStrokeWidth={true}
                strokeWidth={1.5}
              />
              <div className="absolute inset-0 bg-gradient-to-t from-white to-50% group-data-[dark]:from-gray-800 group-data-[dark]:from-[-25%]" />
            </div>

            <div className="relative h-80 shrink-0 p-4">
              {isUsLanguage ? (
                <div className="absolute inset-0 bg-[url(/static/landing/screenshots/en/dividends-v3.png)] bg-[size:610px_320px] bg-[left_32px_top_32px] bg-no-repeat" />
              ) : (
                <div className="absolute inset-0 bg-[url(/static/landing/screenshots/pt/dividends-v3.png)] bg-[size:610px_320px] bg-[left_32px_top_32px] bg-no-repeat" />
              )}
              <div className="absolute inset-0 bg-gradient-to-t from-white to-50% group-data-[dark]:from-gray-800 group-data-[dark]:from-[-25%]" />
            </div>
            <div className="relative p-10">
              <h3 className="font-mono text-xs/5 font-semibold uppercase tracking-widest text-gray-500 data-[dark]:text-gray-400">
                <FormattedMessage defaultMessage="Payout" id="xdVU3q" />
              </h3>
              <p className="mt-1 text-2xl/8 font-bold tracking-tight text-gray-950 group-data-[dark]:text-white">
                <FormattedMessage defaultMessage="Dividendos" id="A7Fc1J" />
              </p>
              <p className="mt-2 max-w-[600px] text-sm/6 text-gray-600 group-data-[dark]:text-gray-400">
                <FormattedMessage
                  defaultMessage="Será que a empresa distribui muito ou pouco dividendo? Descubra através do Dividend Yield e do histórico de distribuição!"
                  id="wM6hx3"
                />
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
