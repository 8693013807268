import Fundamentei from "components/branding/Fundamentei";
import classNames from "components/ui/classNames";
import { preserveOnlyUtmParameters } from "lib/analytics/utm";
import Link from "next/link";
import { useRouter } from "next/router";
import { FormattedMessage } from "react-intl";

export default function SignUpNow() {
  const router = useRouter();
  return (
    <section className="mx-auto flex max-w-7xl flex-col items-center gap-y-12 px-6 py-16 lg:px-0">
      <div className="mx-auto flex max-w-3xl flex-col items-center gap-8 text-center">
        <Fundamentei width={45} height={48} />
        <div className="flex flex-col gap-1">
          <h2 className="text-3xl font-bold tracking-tight text-slate-900 lg:text-4xl">
            <FormattedMessage defaultMessage="Invista nos melhores ativos hoje" id="3fQxcg" />
          </h2>
          <p className="text-lg text-slate-600">
            <FormattedMessage
              defaultMessage="Conte conosco para te ajudar a montar uma carteira excelente de longo prazo!"
              id="d/lsZS"
            />
          </p>
        </div>
        <Link
          href={{
            pathname: "/sign-up",
            query: preserveOnlyUtmParameters(router.query),
          }}
          className={classNames(
            "inline-flex items-center justify-center rounded-lg",
            "px-4 py-3 font-medium shadow",
            "bg-brand text-white",
            "hover:opacity-95",
          )}
        >
          <FormattedMessage defaultMessage="Cadastre-se já" id="4rggWr" />
        </Link>
      </div>
    </section>
  );
}
