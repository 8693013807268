import { connectStats } from "react-instantsearch-dom";
import { FormattedMessage } from "react-intl";

export default connectStats(({ nbHits }) => {
  return (
    <div className="space-y-2 whitespace-nowrap font-aeonik">
      <h1 className="m-0 text-3xl font-bold text-slate-600 dark:text-slate-300">{nbHits}</h1>
      <span className="text-xs font-bold uppercase tracking-wide text-slate-400">
        <FormattedMessage
          defaultMessage="{nbHits, plural, one {Ativo encontrado} other {Ativos encontrados}}"
          id="6wQVh1"
          values={{
            nbHits,
          }}
        />
      </span>
    </div>
  );
});
