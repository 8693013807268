import ConnectedToggle from "components/screener/shared/ConnectedToggle";
import { FormattedMessage } from "react-intl";

export default function IsBrazilianDepositaryReceipt() {
  return (
    <div className="space-y-2">
      <h3 className="font-aeonik text-base font-bold tracking-wide text-zinc-500">
        <FormattedMessage defaultMessage="BDRs" id="jqBdAw" />
      </h3>

      <div className="flex items-center justify-between">
        <span className="flex font-aeonik text-sm font-normal italic tracking-wider text-slate-400">
          <FormattedMessage defaultMessage="Filtre por Ações que são BDRs" id="XkoI6X" />
        </span>

        <ConnectedToggle label="BDRs" attribute="isBrazilianDepositaryReceipt" value={true} />
      </div>
    </div>
  );
}
