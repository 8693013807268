import gql from "graphql-tag";

export default gql`
  query Bookmarks(
    $filterByIssuerType: IssuerType
    $sortField: BookmarksOrder
    $sortDirection: OrderDirection!
    $limit: Int = 20
    $cursor: Cursor
  ) {
    bookmarks(
      filterByIssuerType: $filterByIssuerType
      sortField: $sortField
      sortDirection: $sortDirection
      limit: $limit
      cursor: $cursor
    ) {
      nodes {
        issuer {
          ... on AmericanCompanyIssuer {
            ...AmericanCompanyIssuerCardFields
          }
          ... on BrazilianCompanyIssuer {
            ...BrazilianCompanyIssuerCardFields
          }
          ... on BrazilianRealEstateFund {
            ...BrazilianRealEstateFundCardFields
          }
          ... on BrazilianRealEstateFundIssuer {
            ...BrazilianRealEstateFundIssuerCardFields
          }
        }
        bookmarkedAt
      }
      totalCount
      pageInfo {
        hasNextPage
        cursor
      }
      breakdown {
        stocksCount
        reitsCount
        acoesCount
        fiisCount
      }
    }
  }

  fragment AmericanCompanyIssuerCardFields on AmericanCompanyIssuer {
    _id
    name
    sector
    isAmericanDepositaryReceipt
    isREIT
    shortBusinessDescription
    primaryTickerSymbol
    niceLogo {
      url
    }
    viewerDidBookmark
    marketCapitalization {
      totalCapitalization
    }
    overallRankingRating
  }

  fragment BrazilianRealEstateFundCardFields on BrazilianRealEstateFund {
    _id
    name
    shortDescription
    tickerSymbol
    viewerDidBookmark
    overallRankingRating
  }

  fragment BrazilianCompanyIssuerCardFields on BrazilianCompanyIssuer {
    _id
    name
    sector
    businessDescription
    securities {
      tickerSymbol
    }
    niceLogo {
      url
    }
    viewerDidBookmark
    marketCapitalization {
      totalCapitalization
    }
    overallRankingRating
  }

  fragment BrazilianRealEstateFundIssuerCardFields on BrazilianRealEstateFundIssuer {
    _id
    name
    sector
    tickerSymbolPrefix
    shortDescription
    viewerDidBookmark
    overallRankingRating
  }
`;
