import FundamenteiWithText from "components/branding/FundamenteiWithText";
import LogoSizingProps from "components/branding/LogoSizingProps";
import classNames from "components/ui/classNames";
import { preserveOnlyUtmParameters } from "lib/analytics/utm";
import { LinkAnchorButton } from "lib/miolo";
import Link from "next/link";
import { useRouter } from "next/router";
import { FormattedMessage } from "react-intl";

export default function Header() {
  const router = useRouter();
  return (
    <header className={classNames("flex max-w-7xl flex-col items-center justify-between lg:flex-row", "mx-auto py-16")}>
      <Link
        title="Fundamentei"
        href={{
          pathname: "/",
          query: preserveOnlyUtmParameters(router?.query),
        }}
      >
        <FundamenteiWithText className="inline-flex" {...LogoSizingProps.FundamenteiWithText.VERY_SMALL} />
      </Link>

      <nav className="my-6 flex w-full justify-center gap-x-6 scroll-smooth lg:my-0">
        <a
          className="text-base font-medium tracking-wide text-slate-600 hover:text-slate-950 hover:underline hover:underline-offset-4"
          href="#features"
        >
          <FormattedMessage defaultMessage="Funcionalidades" id="xOKb+g" />
        </a>
        <a
          className="text-base font-medium tracking-wide text-slate-600 hover:text-slate-950 hover:underline hover:underline-offset-4"
          href="#pricing"
        >
          <FormattedMessage defaultMessage="Preço" id="VFnrdw" />
        </a>
        <a
          className="text-base font-medium tracking-wide text-slate-600 hover:text-slate-950 hover:underline hover:underline-offset-4"
          href="#about"
        >
          <FormattedMessage defaultMessage="Sobre" id="/h9LIA" />
        </a>
      </nav>

      <nav className="flex flex-col items-center gap-y-4 text-center lg:flex-row lg:gap-x-6 lg:text-left">
        <Link
          href={{
            pathname: "/login",
            query: preserveOnlyUtmParameters(router?.query),
          }}
          passHref={true}
          legacyBehavior={true}
        >
          <LinkAnchorButton className="text-base font-medium tracking-widest">Login</LinkAnchorButton>
        </Link>

        <Link
          href={{
            pathname: "/sign-up",
            query: preserveOnlyUtmParameters(router?.query),
          }}
          className={classNames(
            "m-0 box-border inline-block select-none overflow-visible whitespace-nowrap rounded-[20px] border border-brand",
            "appearance-none px-4 py-1 text-base font-medium text-brand no-underline",
            "transition-all duration-75 ease-in-out",
            "bg-brand text-white hover:opacity-90",
            "focus:shadow-[0_0_0_3px_#d1d9fa] focus:outline-none",
            "active:shadow-transparent",
          )}
        >
          <FormattedMessage defaultMessage="Cadastre-se" description="Botão no cabeçalho da landing page" id="EoIVuI" />
        </Link>
      </nav>
    </header>
  );
}
