import ConnectedSliderRange from "components/screener/shared/ConnectedSliderRange";
import { FormattedMessage, useIntl } from "react-intl";

export default function FreeFloat() {
  const intl = useIntl();

  return (
    <div className="space-y-4">
      <div className="space-y-1">
        <h3 className="font-aeonik text-base font-bold uppercase tracking-wide text-zinc-500">
          <FormattedMessage defaultMessage="Free Float" id="bQN4OR" />
        </h3>
        <span className="flex font-aeonik text-sm font-normal italic tracking-wider text-slate-400">
          <FormattedMessage defaultMessage="Selecione uma faixa de Free Float" id="K4g2Y9" />
        </span>
      </div>

      <div className="space-y-8">
        <ConnectedSliderRange
          attribute="freeFloat.commonStock"
          label={intl.formatMessage({
            defaultMessage: "Ações ON",
            id: "ep98Aj",
          })}
          accessibilityLabels={[
            intl.formatMessage({
              defaultMessage: "Free Float mínimo das Ações Ordinárias",
              id: "o99YL9",
            }),
            intl.formatMessage({
              defaultMessage: "Free Float máximo das Ações Ordinárias",
              id: "/mhUVX",
            }),
          ]}
        />

        <ConnectedSliderRange
          attribute="freeFloat.preferredStock"
          label={intl.formatMessage({
            defaultMessage: "Ações PN",
            id: "QDAcUP",
          })}
          accessibilityLabels={[
            intl.formatMessage({
              defaultMessage: "Free Float mínimo das Ações Preferenciais",
              id: "dh6M5J",
            }),
            intl.formatMessage({
              defaultMessage: "Free Float máximo das Ações Preferenciais",
              id: "Zx6QH4",
            }),
          ]}
        />
      </div>
    </div>
  );
}
