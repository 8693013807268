/* eslint-disable react/no-unstable-nested-components */
import parseISO from "date-fns/parseISO";
import Link from "next/link";
import { IStripeSubscriptionStatus } from "types/graphqlTypes";
import { FormattedMessage, useIntl } from "react-intl";
import useSelfie from "../../lib/hooks/useSelfie";

export default function OverdueSubscriptionStickyBanner() {
  const intl = useIntl();
  const user = useSelfie();

  if (!user) {
    return null;
  }

  // @ts-ignore
  // eslint-disable-next-line prefer-destructuring
  const subscription: any = user.subscription;

  if (!subscription || subscription.status !== IStripeSubscriptionStatus.PastDue || !subscription.latestInvoice) {
    return null;
  }

  const { nextPaymentAttempt } = subscription.latestInvoice;

  return (
    <div className="px-6">
      <div className="m-auto mt-8 max-w-screen-legacyDesktop rounded-sm border-b-4 border-rose-600">
        <div className="space-y-2 rounded-t-md bg-rose-100 p-4 font-aeonik shadow-sm">
          <div>
            <h2 className="text-lg font-bold text-rose-600">
              <FormattedMessage
                defaultMessage="Fala Investidor!"
                id="hc+0nz"
                description="Banner de assinatura vencida"
              />
            </h2>

            <p className="text-sm font-medium leading-6 tracking-wider text-rose-600">
              <FormattedMessage
                defaultMessage="...não conseguimos realizar a cobrança da sua assinatura Premium no cartão. Por favor, <link>clique aqui para atualizar os dados do seu cartão.</link>"
                id="j15JJy"
                description="Banner de assinatura vencida"
                values={{
                  link: (text) => (
                    <Link
                      href="/billing/payment-methods"
                      as="/billing/payment-methods"
                      className="text-blue-600 hover:underline"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {text}
                    </Link>
                  ),
                }}
              />
            </p>
          </div>

          {nextPaymentAttempt && (
            <p className="text-md font-medium leading-6 tracking-wide text-rose-800">
              <FormattedMessage
                defaultMessage="A próxima tentativa de cobrança será em <strong>{nextPaymentAttempt}</strong>. Regularize seu pagamento o quanto antes para continuar garantindo o seu acesso. Caso falte com o pagamento, seu acesso será cortado."
                id="Ythha6"
                description="Banner de assinatura vencida"
                values={{
                  strong: (text) => (
                    <strong className="rounded-md bg-brand p-1 text-xs font-extrabold text-white">{text}</strong>
                  ),
                  nextPaymentAttempt: intl.formatDate(parseISO(nextPaymentAttempt), {
                    day: "numeric",
                    month: "numeric",
                  }),
                }}
              />
            </p>
          )}
        </div>
      </div>
    </div>
  );
}
