import { NativeSelect } from "lib/miolo";
import type { MenuProvided } from "react-instantsearch-core";
import { connectMenu } from "react-instantsearch-dom";
import { FormattedMessage } from "react-intl";

const GovernmentOwnershipMenu = connectMenu<MenuProvided>(({ items, refine, currentRefinement }) => {
  return (
    <div className="space-y-4 font-aeonik font-normal">
      <h3 className="text-base font-bold uppercase tracking-wide text-zinc-500">
        <FormattedMessage defaultMessage="Governo como Sócio" id="DKBCKD" />
      </h3>

      <NativeSelect value={currentRefinement || ""} onChange={(event) => refine(event.currentTarget.value || "")}>
        <option value="">
          {currentRefinement === null ? (
            <FormattedMessage defaultMessage="Selecionar filtro" id="DBVwZM" />
          ) : (
            <FormattedMessage defaultMessage="Limpar filtro" id="SJdpi2" />
          )}
        </option>

        {items.map((item) => {
          return (
            <option key={item.label} value={item.label}>
              {item.label === "true" ? (
                <FormattedMessage defaultMessage="Qualquer participação" id="8VD0bn" />
              ) : (
                <FormattedMessage defaultMessage="Sem participação" id="iLm3UR" />
              )}
            </option>
          );
        })}
      </NativeSelect>
    </div>
  );
});

export default function GovernmentOwnership() {
  return <GovernmentOwnershipMenu attribute="isGovernmentAShareholder" />;
}
