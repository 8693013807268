import getAssetTypeTabs from "components/landing/helpers/getAssetTypeTabs";
import getPlatformFAQs from "components/landing/helpers/getPlatformFAQs";
import getPlatformFeatures from "components/landing/helpers/getPlatformFeatures";
import getPlatformMetrics from "components/landing/helpers/getPlatformMetrics";
import getTestimonials from "components/landing/helpers/getTestimonials";

import { useIntl } from "react-intl";

export default function useLandingContents() {
  const intl = useIntl();

  const isUsLanguage = !intl.locale.startsWith("pt-BR");

  return {
    assetTypeTabs: getAssetTypeTabs(intl),
    platformMetrics: getPlatformMetrics(intl),
    platformFeatures: getPlatformFeatures(intl, isUsLanguage),
    frequentlyAskedQuestions: getPlatformFAQs(intl),
    testimonials: getTestimonials(isUsLanguage),
    isUsLanguage,
  };
}
