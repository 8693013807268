import { useState } from "react";

interface IInfinitePaginationProps<T> {
  items: T[];
  itemsPerPage: number;
}

export default function useInfinitePagination<T = any>(props: IInfinitePaginationProps<T>) {
  const [offset, setOffset] = useState<number>(1);
  const hasMoreToShow = offset * props.itemsPerPage < props.items.length;

  return {
    items: props.items.slice(0, offset * props.itemsPerPage),
    itemsPerPage: props.itemsPerPage,
    hasMoreToShow,
    showMore: () => {
      if (hasMoreToShow) {
        setOffset(offset + 1);
      }
    },
    reset: () => setOffset(1),
  };
}
