import { NativeSelect } from "lib/miolo";
import type { MenuProvided } from "react-instantsearch-core";
import { connectMenu } from "react-instantsearch-dom";
import { FormattedMessage } from "react-intl";

const RealEstateFundOperatingSegmentMenu = connectMenu<MenuProvided>(({ items, refine, currentRefinement }) => {
  return (
    <div className="space-y-4">
      <h3 className="font-aeonik text-base font-bold uppercase tracking-wide text-zinc-500">
        <FormattedMessage defaultMessage="Segmento" id="9WjEcd" />
      </h3>

      <NativeSelect value={currentRefinement || ""} onChange={(event) => refine(event.currentTarget.value || "")}>
        <option value="">
          <FormattedMessage defaultMessage="Todos" id="P8VfZA" />
        </option>

        {items.map((item) => {
          return (
            <option key={item.label} value={item.label}>
              {item.label}
            </option>
          );
        })}
      </NativeSelect>
    </div>
  );
});

export default function RealEstateFundOperatingSegment() {
  return <RealEstateFundOperatingSegmentMenu attribute="industrySegment" />;
}
