/* eslint-disable @typescript-eslint/no-unused-vars */
import { Separator } from "components/ui/Separator";

interface DividerProps {
  variant?: "faded" | "fade-to-right" | "normal";
}

// @ts-ignore
export default function Divider({ variant = "normal" }: DividerProps) {
  return <Separator />;
}
