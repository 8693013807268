import type { CurrentRefinementsProvided } from "react-instantsearch-core";
import { connectCurrentRefinements } from "react-instantsearch-core";

interface ICurrentRefinementsProps {
  children: (state: CurrentRefinementsProvided) => any;
}

const CurrentRefinements = connectCurrentRefinements<ICurrentRefinementsProps & CurrentRefinementsProvided>(
  ({ children, ...props }) => children(props),
);

export default CurrentRefinements;
