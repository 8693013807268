/* eslint-disable react/jsx-curly-brace-presence */
/* eslint-disable jsx-a11y/accessible-emoji */
import classNames from "components/ui/classNames";
import getBaseUrl from "lib/utils/getBaseUrl";
import { XIcon } from "lucide-react";
import * as React from "react";

interface FundamenteiBlackProps extends React.HTMLAttributes<HTMLDivElement> {
  popUpTitle: React.ReactNode;
  externalLinkText: string;
  externalLinkHref: string;
  children: React.ReactNode;
  onClose: () => void;
}

export default function FundamenteiBlack({
  popUpTitle,
  externalLinkText,
  externalLinkHref,
  children,
  onClose,
  className,
  ...props
}: FundamenteiBlackProps) {
  return (
    <div className="px-6 xl:p-0">
      <div
        className={classNames(
          "relative rounded-md px-6 py-6 font-aeonik",
          `bg-gradient-to-r from-[#3f5fe9] to-gray-500/90`,
          `sm:rounded-xl sm:shadow-lg`,
          className,
        )}
        {...props}
      >
        <div className="relative space-y-4">
          {popUpTitle}

          <div className="flex flex-col items-center justify-between space-y-4 sm:flex-row sm:space-x-2 sm:space-y-0">
            <div className="text-sm font-normal leading-6 tracking-wide text-white md:text-base">{children}</div>

            <a
              href={externalLinkHref}
              target="_blank"
              rel="noopener noreferrer"
              className={classNames(
                "flex w-full items-center justify-center whitespace-nowrap rounded-md bg-white px-12 py-3 text-xs",
                "font-semibold uppercase tracking-wider text-orange-700 shadow-sm md:w-auto md:text-xs",
                "hover:border-solid hover:bg-orange-100 hover:text-orange-700 hover:drop-shadow-sm",
              )}
            >
              {externalLinkText}
            </a>
          </div>

          <div className="flex items-center space-x-2">
            <img
              src={`${getBaseUrl()}/static/people/eduardo-cavalcanti.png`}
              alt="Eduardo Cavalcanti"
              className="h-8 w-8 rounded-3xl squircle md:h-12 md:w-12"
              width={48}
              height={48}
            />

            <div className="text-white">
              <h3 className="text-sm font-semibold drop-shadow-2xl md:text-sm">Eduardo Cavalcanti</h3>
              <p className="text-[10px] font-normal italic tracking-wide text-gray-200 md:text-xs">
                Cofundador do Fundamentei
              </p>
            </div>
          </div>
        </div>

        <div className="absolute right-0 top-0 -m-3">
          <button
            type="button"
            aria-label="Fechar"
            onClick={() => onClose()}
            className="cursor-pointer rounded-full bg-red-500 p-1 text-white shadow-lg hover:bg-red-400"
          >
            <XIcon className="size-4" strokeWidth={3} />
          </button>
        </div>
      </div>
    </div>
  );
}
