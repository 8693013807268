import CompanyCard from "components/screener/cards/CompanyCard";
import classNames from "components/ui/classNames";

import type { IIssuerObject } from "lib/algolia/types/assets";
import type { InfiniteHitsProvided } from "react-instantsearch-core";
import { connectInfiniteHits } from "react-instantsearch-core";
import { FormattedMessage } from "react-intl";

const ScreenerHits = connectInfiniteHits<InfiniteHitsProvided<IIssuerObject>, IIssuerObject>(
  ({ hits, hasMore, refineNext }) => {
    const assets = hits.filter(Boolean);

    if (assets.length === 0) {
      return (
        <div className="flex flex-col items-center justify-between space-y-10 font-aeonik">
          <div className="space-y-1 text-center">
            <h1 className="text-2xl font-bold text-slate-700 dark:text-slate-300">
              <FormattedMessage defaultMessage="Não encontramos nenhum resultado." id="BLiXUY" />
            </h1>
            <p className="text-sm font-normal tracking-wider text-slate-500 dark:text-slate-400">
              <FormattedMessage defaultMessage="Altere os filtros da sua busca e tente novamente." id="niECj3" />
            </p>
          </div>
        </div>
      );
    }

    return (
      <div className="space-y-7">
        <div className="relative grid grid-cols-1 gap-6 md:grid-cols-2 lg:grid-cols-3">
          {assets.map((asset) => {
            if (
              asset.type === "BRAZILIAN_COMPANY" ||
              asset.type === "AMERICAN_COMPANY" ||
              asset.type === "AMERICAN_REIT" ||
              asset.type === "BRAZILIAN_REAL_ESTATE_FUND"
            ) {
              return <CompanyCard key={asset.objectID} company={asset} />;
            }

            return null;
          })}
        </div>

        {hasMore && (
          <div className="text-center">
            <button
              type="button"
              onClick={() => refineNext()}
              className={classNames(
                "w-full rounded-xl p-3 text-base font-medium tracking-wide text-blue-600",
                "hover:bg-slate-100 hover:text-slate-700",
                "dark:bg-slate-950 dark:text-slate-300 dark:hover:opacity-80",
              )}
            >
              <FormattedMessage defaultMessage="Ver mais ativos" id="pi2tid" />
            </button>
          </div>
        )}
      </div>
    );
  },
);

export default ScreenerHits;
