"use client";

import * as TooltipPrimitive from "@radix-ui/react-tooltip";
import classNames from "components/ui/classNames";
import { Popover, PopoverContent, PopoverTrigger } from "components/ui/Popover";
import useCloseTimeout from "lib/hooks/useCloseTimeout";
import * as React from "react";

const TooltipProvider = TooltipPrimitive.Provider;

// eslint-disable-next-line react/function-component-definition
const Tooltip = ({ ...props }) => <TooltipPrimitive.Root {...props} />;
Tooltip.displayName = TooltipPrimitive.Tooltip.displayName;

const TooltipTrigger = TooltipPrimitive.Trigger;

const TooltipContent = React.forwardRef<
  React.ElementRef<typeof TooltipPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof TooltipPrimitive.Content>
>(({ className, sideOffset = 4, ...props }, ref) => (
  <TooltipPrimitive.Content
    ref={ref}
    sideOffset={sideOffset}
    className={classNames(
      "z-50 overflow-hidden rounded-md border border-slate-100 bg-white px-3 py-1.5 text-sm text-slate-700 shadow-md animate-in fade-in-50",
      "data-[side=bottom]:slide-in-from-top-1 data-[side=left]:slide-in-from-right-1 data-[side=right]:slide-in-from-left-1 data-[side=top]:slide-in-from-bottom-1",
      "dark:border-slate-950 dark:bg-black dark:text-slate-300",
      className,
    )}
    {...props}
  />
));

TooltipContent.displayName = TooltipPrimitive.Content.displayName;

interface ISimpleTextTooltipProps {
  content: React.ReactNode;
  className?: string;
  triggerClassName?: string;
  delayDuration?: number;
  disableHoverableContent?: boolean;
  children: React.ReactNode;
  align?: "start" | "center" | "end";
  side?: "left" | "right" | "bottom" | "top";
  asChild?: boolean;
}
function SimpleTextTooltip({
  content,
  className,
  triggerClassName,
  delayDuration,
  disableHoverableContent,
  children,
  align = "center",
  side = "top",
  asChild = true,
}: ISimpleTextTooltipProps) {
  const [isOpen, setIsOpen] = useCloseTimeout(3000);

  return (
    <>
      <TooltipProvider delayDuration={delayDuration || 0} disableHoverableContent={disableHoverableContent}>
        <Tooltip>
          <TooltipTrigger asChild={asChild} className={classNames("hidden", "lg:flex", triggerClassName)}>
            {children}
          </TooltipTrigger>
          <TooltipContent
            side={side}
            sideOffset={12}
            align={align}
            className={classNames("max-w-xs rounded-xl border border-transparent bg-black text-white", className)}
          >
            {typeof content === "string" ? (
              <strong className="font-medium tracking-wide">{content}</strong>
            ) : (
              <div>{content}</div>
            )}
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>

      <Popover open={isOpen} onOpenChange={(open) => setIsOpen(open)}>
        <PopoverTrigger asChild={asChild} className={classNames("flex", "lg:hidden", triggerClassName)}>
          {children}
        </PopoverTrigger>
        <PopoverContent
          side={side}
          sideOffset={12}
          align={align}
          className={classNames(
            "max-w-40 rounded-xl border border-transparent bg-black px-2 text-sm text-white",
            className,
          )}
        >
          <strong className="font-medium tracking-wide">{content}</strong>
        </PopoverContent>
      </Popover>
    </>
  );
}

export { Tooltip, TooltipTrigger, TooltipContent, TooltipProvider, SimpleTextTooltip };
