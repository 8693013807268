import type { CurrentRefinementsProvided } from "react-instantsearch-core";
import { connectCurrentRefinements } from "react-instantsearch-dom";
import classNames from "components/ui/classNames";
import { FormattedMessage } from "react-intl";

interface IClearAllFiltersProps {
  onClick?: () => void;
}

const ClearAllFilters = connectCurrentRefinements<CurrentRefinementsProvided & IClearAllFiltersProps>(
  ({ items, refine, onClick }) => {
    return (
      <div className="w-full text-right">
        <button
          type="button"
          className={classNames(
            "bg-red-50 font-aeonik text-xs font-medium uppercase tracking-wider text-red-700",
            "hover:bg-red-500 hover:text-white",
            "w-full rounded-xl border border-red-900/20 p-4",
            "lg:w-auto lg:rounded-full lg:border-transparent lg:px-3 lg:py-1",
            "dark:bg-red-600/20 dark:text-red-300",
          )}
          disabled={items.length === 0}
          onClick={() => {
            if (typeof onClick === "function") {
              onClick();
            }
            refine(items);
          }}
        >
          <FormattedMessage defaultMessage="Remover filtros" id="f/KyU9" />
        </button>
      </div>
    );
  },
);

export default ClearAllFilters;
