export default function ChristTheRedeemer(props: React.SVGProps<any>) {
  return (
    <svg width={25} height={35} viewBox="0 0 25 35" {...props}>
      <g fillRule="nonzero" fill="none">
        <path
          d="M20.109 32.813H4.89v-17.5c0-2.417 1.947-4.376 4.348-4.376h6.522c2.401 0 4.348 1.96 4.348 4.376v17.5z"
          fill="#C8F5F0"
        />
        <path fill="#A494A4" d="M8.152 27.89h8.696v4.376H8.152z" />
        <path
          d="M14.13 4.922V2.188c0-.907-.73-1.641-1.63-1.641-.9 0-1.63.734-1.63 1.64v2.735L3.456 6.414c-.491.099-.195.149-.195.149L4.43 8.505c.295.49.824.79 1.395.79h3.958v18.595h5.434V9.297h3.958c.57 0 1.1-.3 1.395-.791l1.17-1.944-.196-.148-7.414-1.492z"
          fill="#FFEACD"
        />
        <path fill="#FFD3AE" d="M15.217 21.328l-5.434-3.281v9.844h5.434z" />
        <path d="M5.978 33.36c0 .905.73 1.64 1.63 1.64h9.783c.9 0 1.63-.735 1.63-1.64H5.979z" fill="#D1C9D1" />
        <path
          d="M19.022 33.906H5.978c-.6 0-1.087-.49-1.087-1.093l.544-.547h14.13l.544.547a1.09 1.09 0 01-1.087 1.093z"
          fill="#A494A4"
        />
        <path
          d="M15.543 17.61l-1.521-1.15a1.101 1.101 0 01-.435-.874v-9.57c0-.302.244-.547.543-.547.3 0 .544-.245.544-.547a.545.545 0 00-.544-.547c-.899 0-1.63.736-1.63 1.64v1.456L9.235 4.944a.541.541 0 00-.762.1.55.55 0 00.1.767l3.927 3.04v1.523L6.31 5.582a.541.541 0 00-.763.1.55.55 0 00.1.767l6.853 5.305v3.832c0 .685.325 1.339.87 1.75l1.521 1.148a.538.538 0 00.761-.109.55.55 0 00-.109-.766z"
          fill="#7C657C"
        />
        <path
          d="M24.457 6.016h-1.421a7.04 7.04 0 01-1.386-.138l-6.976-1.404V2.187A2.183 2.183 0 0012.5 0a2.183 2.183 0 00-2.174 2.188v2.286L3.35 5.878a7.04 7.04 0 01-1.386.138H.544c-.3 0-.544.244-.544.546 0 .303.243.547.543.547h1.421c.319 0 .639-.02.956-.057L3.965 8.79a2.183 2.183 0 001.86 1.055h3.414V27.89c0 .302.243.547.544.547.3 0 .543-.245.543-.547v-8.878l4.348 2.625v6.253c0 .302.243.547.543.547.3 0 .544-.245.544-.547V9.844h3.414c.756 0 1.469-.404 1.86-1.055l1.045-1.737c.317.038.637.057.956.057h1.42c.3 0 .544-.244.544-.546a.545.545 0 00-.543-.547zm-4.352 2.207a1.092 1.092 0 01-.93.527h-3.958c-.3 0-.543.245-.543.547v11.065l-4.348-2.625v-8.44a.545.545 0 00-.543-.547H5.825c-.378 0-.735-.202-.93-.527l-.827-1.375 6.908-1.39a.546.546 0 00.437-.536V2.188c0-.604.488-1.094 1.087-1.094.6 0 1.087.49 1.087 1.093v2.735c0 .26.183.485.437.536l6.908 1.39-.827 1.375z"
          fill="#503250"
        />
        <path
          d="M16.848 27.344h-3.805v-6.69l-1.086-.657v7.347H8.152c-.3 0-.543.245-.543.547v4.375c0 .302.243.547.543.547.3 0 .544-.245.544-.547v-3.828h7.608v3.828c0 .302.244.547.544.547.3 0 .543-.245.543-.547V27.89a.545.545 0 00-.543-.547z"
          fill="#7C657C"
        />
        <path d="M20.109 32.833H4.9a1.09 1.09 0 011.087-1.094h13.035c.6 0 1.087.49 1.087 1.094z" fill="#503250" />
      </g>
    </svg>
  );
}
