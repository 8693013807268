import gql from "graphql-tag";

export default gql`
  fragment TrailingReturnsFields on TrailingReturns {
    exchangeId
    tickerSymbol

    close
    asOfDate
    numberOfTrades
    minDate
    maxDate
    daysTrading
    yearsTrading

    oneDay
    oneWeek
    oneMonth
    threeMonths
    sixMonths
    oneYear
    threeYears
    fiveYears
    fiveYearsAnnualized
    sevenYears
    tenYears
    tenYearsAnnualized
    monthToDate
    yearToDate
    quarterToDate
    allTime
    allTimeAnnualized

    prices {
      oneDay {
        tradeDate
        close
      }
      oneWeek {
        tradeDate
        close
      }
      oneMonth {
        tradeDate
        close
      }
      threeMonths {
        tradeDate
        close
      }
      sixMonths {
        tradeDate
        close
      }
      oneYear {
        tradeDate
        close
      }
      threeYears {
        tradeDate
        close
      }
      fiveYears {
        tradeDate
        close
      }
      sevenYears {
        tradeDate
        close
      }
      tenYears {
        tradeDate
        close
      }
      monthToDate {
        tradeDate
        close
      }
      yearToDate {
        tradeDate
        close
      }
      quarterToDate {
        tradeDate
        close
      }
      allTime {
        tradeDate
        close
      }
    }
  }
`;
