import ConnectedSliderRange from "components/screener/shared/ConnectedSliderRange";
import { FormattedMessage, useIntl } from "react-intl";

export default function NetMargin() {
  const intl = useIntl();

  return (
    <div className="space-y-4">
      <div className="space-y-1">
        <h3 className="font-aeonik text-base font-bold uppercase tracking-wide text-zinc-500">
          <FormattedMessage defaultMessage="Margem" id="jyYksE" />
        </h3>
        <span className="flex font-aeonik text-sm font-normal italic tracking-wider text-slate-400">
          <FormattedMessage defaultMessage="Filtre por margem de lucro." id="UhOrmU" />
        </span>
      </div>

      <ConnectedSliderRange
        attribute="lastYearlyFinancials.netMargin"
        label={intl.formatMessage({
          defaultMessage: "Margem líquida",
          id: "eyemBh",
        })}
        accessibilityLabels={[
          intl.formatMessage({
            defaultMessage: "Margem de lucro mínima",
            id: "rsKHBg",
          }),
          intl.formatMessage({
            defaultMessage: "Margem de lucro máxima",
            id: "D2VIRs",
          }),
        ]}
      />
    </div>
  );
}
