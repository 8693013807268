import gql from "graphql-tag";

export default gql`
  mutation SignInWithGoogleOAuthIdToken($idToken: String!, $utms: UTMParametersInput) {
    signInWithGoogleOAuthIdToken(idToken: $idToken, utms: $utms) {
      email
      isRecentlyCreatedUser
      accessToken
    }
  }
`;
