import { NativeSelect } from "lib/miolo";
import type { MenuProvided } from "react-instantsearch-core";
import { connectMenu } from "react-instantsearch-dom";
import { FormattedMessage } from "react-intl";

const RealEstateFundManagementMenu = connectMenu<MenuProvided>(({ refine, currentRefinement }) => {
  return (
    <div className="space-y-4">
      <h3 className="font-aeonik text-base font-bold uppercase tracking-wide text-zinc-500">
        <FormattedMessage defaultMessage="Gestão" id="gRLfw9" />
      </h3>

      <NativeSelect value={currentRefinement || ""} onChange={(event) => refine(event.currentTarget.value || "")}>
        <option value="">
          <FormattedMessage defaultMessage="Todos" id="P8VfZA" />
        </option>
        <option value="Ativa">
          <FormattedMessage defaultMessage="Ativa" id="YmDxXk" />
        </option>
        <option value="Passiva">
          <FormattedMessage defaultMessage="Passiva" id="iA8XO4" />
        </option>
      </NativeSelect>
    </div>
  );
});

export default function RealEstateFundManagement() {
  return <RealEstateFundManagementMenu attribute="management" />;
}
