import { gql } from "@apollo/client";
import AssetPortfolioPositionFields from "components/portfolio/fragments/AssetPortfolioPositionFields";

export default gql`
  query AssetPortfolioPositions {
    assetPortfolioPositions {
      ...AssetPortfolioPositionFields
    }
  }

  ${AssetPortfolioPositionFields}
`;
