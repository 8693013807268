import useLandingContents from "components/landing/hooks/useLandingContents";
import classNames from "components/ui/classNames";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "components/ui/Tabs";
import { ArrowUpRightIcon } from "lucide-react";
import { useState } from "react";
import { FormattedMessage } from "react-intl";

export default function PlatformAssetTypeSection() {
  const { assetTypeTabs, isUsLanguage } = useLandingContents();

  const [tab, setTab] = useState(isUsLanguage ? "STOCKS" : "ACOES");

  return (
    <section className="space-y-12 border-y border-neutral-200 bg-slate-900 px-6 py-20 lg:px-0">
      <header className="mx-auto flex max-w-4xl flex-col gap-y-2 text-center">
        <h2 className="max-w-4xl text-pretty text-4xl font-medium tracking-tighter text-white sm:text-5xl">
          <FormattedMessage defaultMessage="Invista nos Melhores Ativos da Bolsa" id="RudQ7f" />
        </h2>
        <p className="text-lg text-slate-300">
          <FormattedMessage
            defaultMessage="Filtre por setor, país, valor de mercado e diversas outras opções que facilitam seus estudos!"
            id="x8DXVS"
          />
        </p>
      </header>

      <div className="mx-auto flex max-w-7xl flex-col items-center gap-y-16">
        <Tabs className="w-full space-y-4" defaultValue="ACOES" value={tab} onValueChange={setTab}>
          <TabsList
            className={classNames("grid w-full grid-cols-1 gap-6 bg-transparent md:grid-cols-2 lg:grid-cols-4")}
          >
            {assetTypeTabs.map((node, index) => (
              <TabsTrigger
                key={node.value}
                value={node.value}
                className={classNames(
                  "group flex flex-col items-center gap-2 rounded-xl border border-transparent",
                  "items-start bg-slate-800 px-4 py-5 text-left",
                  "transition-all duration-200 ease-in-out",
                  "data-[state=inactive]:hover:bg-slate-700",
                  "data-[state=active]:bg-sky-800/30 data-[state=active]:opacity-100",
                  "data-[state=active]:rotate-1 data-[state=active]:shadow",
                  { "lg:col-start-2": index === 0 && isUsLanguage },
                )}
              >
                <div className="flex w-full items-center justify-between">
                  <h3
                    className={classNames(
                      "text-xl font-bold text-slate-200",
                      "group-data-[state=active]:font-black group-data-[state=active]:text-sky-200",
                    )}
                  >
                    {node.title}
                  </h3>
                  <div
                    className={classNames({
                      hidden: node.value !== tab,
                    })}
                  >
                    <ArrowUpRightIcon
                      size={16}
                      strokeWidth={2}
                      absoluteStrokeWidth={true}
                      className="stroke-zinc-400"
                    />
                  </div>
                </div>
                <p className="text-sm tracking-wide text-slate-400 group-data-[state=active]:text-sky-100/60">
                  {node.description}
                </p>
              </TabsTrigger>
            ))}
          </TabsList>

          {assetTypeTabs.map((node) => (
            <TabsContent key={node.value} value={node.value} className="border-none p-0">
              {node.content}
            </TabsContent>
          ))}
        </Tabs>
      </div>
    </section>
  );
}
