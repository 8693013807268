/* eslint-disable react/no-unescaped-entities */
import getBaseUrl from "lib/utils/getBaseUrl";
import { FormattedMessage, useIntl } from "react-intl";

export default function AboutUs() {
  const intl = useIntl();
  const team = [
    {
      fullName: intl.formatMessage({ defaultMessage: "Eduardo Cavalcanti", id: "dwdneP" }),
      pictureUrl: `${getBaseUrl()}/static/people/v2/eduardo.png`,
      jobTitle: intl.formatMessage({ defaultMessage: "CEO, Cofundador", id: "bKQUqJ" }),
      biography: intl.formatMessage({
        defaultMessage: `Pós-graduado em Finanças, Investimentos e Banking pela PUCRS, Consultor de Valores Mobiliários pela CVM, Analisa CNPI pela APIMEC e investidor há 12 anos na Bolsa de Valores, com mais de 5.000 alunos tendo passado pelos seus cursos 100% focados no método Buy&Hold.`,
        id: "UVIzgl",
      }),
    },
    {
      fullName: intl.formatMessage({ defaultMessage: "Andrey Vital", id: "NW0c6C" }),
      pictureUrl: `${getBaseUrl()}/static/people/v2/andrey.png`,
      jobTitle: intl.formatMessage({ defaultMessage: "CTO, Cofundador", id: "zJ3zvK" }),
      biography: intl.formatMessage({
        defaultMessage:
          "Desenvolvedor de Software há mais de 10 anos. Trabalhou em empresas como Govlaunch, ClassDojo, DigitalRelab, VNA Group e atualmente está focado em criar o melhor site de análise fundamentalista do mundo.",
        id: "ONkdt/",
      }),
    },
    {
      fullName: intl.formatMessage({ defaultMessage: "Raphael Lucas", id: "B4QtcG" }),
      pictureUrl: `${getBaseUrl()}/static/people/v2/raphael.png`,
      jobTitle: intl.formatMessage({ defaultMessage: "Full-Stack Developer", id: "L+/XTq" }),
      biography: intl.formatMessage({
        defaultMessage: `Desenvolvedor Full-Stack com mais de 8 anos de experiência, Trabalhou na TOTVS, Zappts e Raro Labs. É formado pela PUC Minas em Sistemas de Informação. Apaixonado por tecnologia & mercado financeiro, soma com sua expertise para desenvolver soluções criativas para os investidores.`,
        id: "WgfxgM",
      }),
    },
    {
      fullName: intl.formatMessage({ defaultMessage: "Arthur Svidzinski", id: "FfDCjx" }),
      pictureUrl: `${getBaseUrl()}/static/people/v2/asvid.png`,
      jobTitle: intl.formatMessage({ defaultMessage: "Analista", id: "kyenQ8" }),
      biography: intl.formatMessage({
        defaultMessage:
          "Analista CNPI pela APIMEC e investidor há mais de 15 anos. Desde 2019 atua de forma ativa no seu canal do YouTube ajudando pessoas que desejam aprender mais sobre análise de fundamentalista de Ações, Stocks e REITs, visando escolher os melhores ativos para uma carteira de longo prazo focada no método Buy&Hold.",
        id: "7lJmQF",
      }),
    },
  ];

  return (
    <section id="about" className="space-y-16 border-b border-slate-200 bg-slate-50 px-6 py-12 pb-16 lg:px-0 lg:py-20">
      <header className="mx-auto flex max-w-4xl flex-col">
        <div className="space-y-1 text-center">
          <h3 className="max-w-4xl text-pretty text-4xl font-medium tracking-tighter text-slate-950 sm:text-5xl">
            <FormattedMessage defaultMessage="Quem somos" id="hzcL6Y" />
          </h3>
          <p className="text-lg text-gray-600">
            <FormattedMessage defaultMessage="Conheça a equipe por trás do Fundamentei" id="TZb7dO" />
          </p>
        </div>
      </header>

      <div className="mx-auto flex max-w-4xl flex-col gap-y-12">
        {team.map(({ fullName, pictureUrl, jobTitle, biography }) => (
          <div key={fullName} className="flex flex-col items-center gap-6 text-center sm:flex-row sm:text-left">
            <img
              src={pictureUrl}
              alt={fullName}
              className="inline-flex aspect-square size-32 flex-nowrap squircle"
              loading="lazy"
              decoding="async"
            />
            <div className="flex flex-col gap-3">
              <div className="flex flex-col gap-0.5">
                <h3 className="text-2xl font-bold tracking-tight text-slate-950">{fullName}</h3>
                <span className="text-base font-medium tracking-wide text-brand">{jobTitle}</span>
              </div>
              <p className="text-pretty tracking-wide text-slate-600">{biography}</p>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
}
