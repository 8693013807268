/* eslint-disable react/no-unstable-nested-components */
import FundamenteiBlack from "components/advertisements/FundamenteiBlack";
import TwoPassRender from "components/shared/TwoPassRender";
import isWithinInterval from "date-fns/isWithinInterval";
import uniq from "lodash/fp/uniq";
import type { ReactNode } from "react";
import { useState } from "react";

interface BlackPopUp {
  key: string;
  title: ReactNode;
  popUpContent: ReactNode;
  externalLinkText: string;
  externalLinkHref: string;
  rootClassName?: string;
  period: [Date, Date];
}

interface BlackPopUpsProps {
  debug?: boolean;
  currentDate: Date;
  popUps: BlackPopUp[];
}

const FUNDAMENTEI_BLACK_POPUPS_KEY = "@fundamentei/blackPopUps";
export default function FundamenteiBlackPopUps({ debug = false, currentDate, popUps }: BlackPopUpsProps) {
  const [closedPopUps, setClosedPopUps] = useState<string[]>(() => {
    if (typeof localStorage !== "undefined") {
      const popUps = localStorage.getItem(FUNDAMENTEI_BLACK_POPUPS_KEY);
      if (!popUps) {
        return [];
      }
      try {
        return JSON.parse(popUps) || [];
      } catch {
        return [];
      }
    }
    return [];
  });

  if (debug) {
    return (
      <TwoPassRender>
        {() => {
          return (
            <div className="m-auto mb-0 mt-8 max-w-7xl space-y-8">
              {popUps.map((popUp) => {
                return (
                  <div key={popUp.key} className="m-auto mb-0 mt-8 max-w-7xl space-y-8">
                    <FundamenteiBlack
                      popUpTitle={popUp.title}
                      className={popUp.rootClassName}
                      externalLinkText={popUp.externalLinkText}
                      externalLinkHref={popUp.externalLinkHref}
                      onClose={() => {
                        setClosedPopUps((closedPopUps) => {
                          const nextClosedPopUps = uniq(closedPopUps.concat(popUp.key));
                          localStorage.setItem(FUNDAMENTEI_BLACK_POPUPS_KEY, JSON.stringify(nextClosedPopUps));
                          return nextClosedPopUps;
                        });
                      }}
                    >
                      {popUp.popUpContent}
                    </FundamenteiBlack>
                  </div>
                );
              })}
            </div>
          );
        }}
      </TwoPassRender>
    );
  }

  const popUp = popUps.find(({ period }) => {
    const [start, end] = period;
    return isWithinInterval(currentDate, {
      start,
      end,
    });
  });

  if (!popUp) {
    return null;
  }

  const hasClosedPopUp = closedPopUps.includes(popUp.key);
  return (
    <TwoPassRender>
      {() => {
        if (hasClosedPopUp) {
          return null;
        }
        return (
          <div key={popUp.key} className="m-auto mb-0 mt-8 max-w-7xl space-y-8">
            <FundamenteiBlack
              popUpTitle={popUp.title}
              className={popUp.rootClassName}
              externalLinkText={popUp.externalLinkText}
              externalLinkHref={popUp.externalLinkHref}
              onClose={() => {
                setClosedPopUps((closedPopUps) => {
                  const nextClosedPopUps = uniq(closedPopUps.concat(popUp.key));
                  localStorage.setItem(FUNDAMENTEI_BLACK_POPUPS_KEY, JSON.stringify(nextClosedPopUps));
                  return nextClosedPopUps;
                });
              }}
            >
              {popUp.popUpContent}
            </FundamenteiBlack>
          </div>
        );
      }}
    </TwoPassRender>
  );
}
