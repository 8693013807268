import Divider from "components/screener/Divider";
import ToggableRefinementList from "components/screener/refinements/ToggableRefinementList";
import classNames from "components/ui/classNames";
import useInfinitePagination from "lib/hooks/useInfinitePagination";
import { MiniSearchInput } from "lib/miolo";
import deburr from "lodash/fp/deburr";
import orderBy from "lodash/fp/orderBy";
import { useEffect, useState } from "react";
import type { RefinementListProvided } from "react-instantsearch-core";
import { connectRefinementList } from "react-instantsearch-dom";
import { FormattedMessage, useIntl } from "react-intl";

interface IndustrySectorRefinementListProps {
  title?: string;
  hideDescription?: boolean;
  isChoosingSectors?: boolean;
  itemsPerPage?: number;
  onToggleChooseSectors?: (isChoosingSectors: boolean) => void;
}

const IndustrySectorRefinementList = connectRefinementList<IndustrySectorRefinementListProps & RefinementListProvided>(
  ({ title, hideDescription, itemsPerPage = 10, isChoosingSectors, onToggleChooseSectors, items, refine }) => {
    const intl = useIntl();
    const options = orderBy(["isRefined", "count", "label"], ["desc", "desc", "asc"], items);
    const [searchQuery, setSearchQuery] = useState<string>("");

    const pagination = useInfinitePagination({
      itemsPerPage,
      items: options.filter((item) => {
        if (searchQuery && searchQuery.trim()) {
          const cleanSearchQuery = searchQuery.trim();

          return deburr(item.label).toLowerCase().includes(deburr(cleanSearchQuery).toLowerCase());
        }

        return true;
      }),
    });

    useEffect(() => {
      if (searchQuery !== "") {
        pagination.reset();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchQuery]);

    const refinements = items.filter((item) => item.isRefined);

    if (!isChoosingSectors) {
      return (
        <div className="space-y-4">
          <div className="space-y-1">
            <h3 className="font-aeonik text-base font-bold uppercase tracking-wide text-zinc-500">
              {title || <FormattedMessage defaultMessage="Setor" id="x0EejY" />}
            </h3>

            {refinements.length > 0 && (
              <div className="flex items-center space-x-1">
                <span className="font-aeonik text-sm font-medium italic tracking-wide text-slate-400">
                  <FormattedMessage defaultMessage="Filtrando por" id="bNHElY" />
                </span>

                <strong className="rounded-xl border border-violet-300 bg-violet-50 px-2 py-0.5 text-xs font-bold text-violet-700">
                  <FormattedMessage
                    defaultMessage="{count, plural, one {# setor} other {# setores}}"
                    id="ktWZqI"
                    values={{
                      count: refinements.length,
                    }}
                  />
                </strong>
              </div>
            )}

            {refinements.length === 0 && hideDescription !== true && (
              <div>
                <span className="flex font-aeonik text-sm font-normal italic tracking-wider text-slate-400">
                  <FormattedMessage defaultMessage="Filtre empresas por setor." id="SyCLpl" />
                </span>
              </div>
            )}
          </div>

          {onToggleChooseSectors && (
            <button
              type="button"
              className={classNames(
                "font-aeonik text-sm font-medium tracking-wide text-blue-600",
                "hover:underline",
                "disabled:text-slate-400",
                "dark:text-blue-300",
              )}
              onClick={() => onToggleChooseSectors(true)}
              disabled={items.length === 0}
            >
              {refinements.length > 0 ? (
                <FormattedMessage
                  defaultMessage="Escolher outros setores ({count})"
                  id="N5RV8q"
                  values={{
                    count: items.length,
                  }}
                />
              ) : (
                <FormattedMessage
                  defaultMessage="Escolher setores ({count})"
                  id="aVq/Os"
                  values={{
                    count: items.length,
                  }}
                />
              )}
            </button>
          )}
        </div>
      );
    }

    return (
      <div className="space-y-4">
        <div className="space-y-1">
          <h3 className="font-aeonik text-base font-bold uppercase tracking-wide text-zinc-500">
            {title || <FormattedMessage defaultMessage="Setor" id="x0EejY" />}
          </h3>
          {hideDescription ? null : (
            <span className="flex font-aeonik text-sm font-normal tracking-wide text-slate-400">
              <FormattedMessage defaultMessage="Escolha um ou vários setores." id="03B3Cy" />
            </span>
          )}
        </div>

        {options.length > 10 && (
          <MiniSearchInput
            value={searchQuery}
            placeholder={intl.formatMessage({
              defaultMessage: "Pesquisar",
              id: "keT/wF",
            })}
            onChange={(searchQuery) => setSearchQuery(searchQuery)}
          />
        )}

        {pagination.items.length > 0 && (
          <>
            <ToggableRefinementList items={pagination.items} refine={refine} />

            {pagination.hasMoreToShow && (
              <div className="text-center">
                <button
                  type="button"
                  onClick={pagination.showMore}
                  className={classNames(
                    "w-full rounded-xl p-1.5 py-2 text-sm font-medium tracking-wide text-blue-600",
                    "hover:bg-slate-100 hover:text-slate-700",
                    "dark:text-blue-300 dark:hover:bg-slate-950 dark:hover:opacity-80",
                  )}
                >
                  <FormattedMessage defaultMessage="Ver mais setores" id="R2d9f8" />
                </button>
              </div>
            )}

            {onToggleChooseSectors && (
              <>
                <Divider variant="faded" />

                <div className="text-center">
                  <button
                    type="button"
                    onClick={() => {
                      onToggleChooseSectors(false);
                      pagination.reset();
                    }}
                    className={classNames(
                      "w-full rounded-xl p-1.5 py-2 text-sm font-medium tracking-wide text-blue-600",
                      "hover:bg-slate-100 hover:text-slate-700",
                      "dark:text-blue-300 dark:hover:bg-slate-950 dark:hover:opacity-80",
                    )}
                  >
                    <FormattedMessage defaultMessage="Ver todos os filtros" id="FkAt7o" />
                  </button>
                </div>
              </>
            )}
          </>
        )}
      </div>
    );
  },
);

interface IndustrySectorProps {
  isChoosingSectors: boolean;
  onToggleChooseSectors: (isChoosingSectors: boolean) => void;
}

export function IndustrySector({ isChoosingSectors, onToggleChooseSectors }: IndustrySectorProps) {
  return (
    <IndustrySectorRefinementList
      isChoosingSectors={isChoosingSectors}
      onToggleChooseSectors={onToggleChooseSectors}
      attribute="industrySector"
      limit={180}
    />
  );
}

interface NAREITClassificationSector {
  attribute: "investmentSector" | "propertySector" | "propertySubsector" | "propertySectorAndSubsector";
  isChoosingSectors?: boolean;
  onToggleChooseSectors?: (isChoosingSectors: boolean) => void;
}
export function NAREITClassificationSector({
  isChoosingSectors,
  onToggleChooseSectors,
  attribute,
}: NAREITClassificationSector) {
  let title = "Setor";
  const intl = useIntl();
  if (attribute === "investmentSector") {
    title = intl.formatMessage({
      defaultMessage: "Tipo",
      id: "wt+XcA",
    });
  }
  if (attribute === "propertySector") {
    title = intl.formatMessage({
      defaultMessage: "Setor",
      id: "x0EejY",
    });
  }
  if (attribute === "propertySubsector") {
    title = intl.formatMessage({
      defaultMessage: "Subsetor",
      id: "CpU+8S",
    });
  }
  if (attribute === "propertySectorAndSubsector") {
    title = intl.formatMessage({
      defaultMessage: "Setor e Subsetor",
      id: "CDNNcs",
    });
  }
  return (
    <IndustrySectorRefinementList
      title={title}
      isChoosingSectors={isChoosingSectors}
      onToggleChooseSectors={onToggleChooseSectors}
      attribute={`nareitClassification.${attribute}`}
      hideDescription={true}
      itemsPerPage={5}
      limit={180}
    />
  );
}
