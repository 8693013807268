import ConnectedSliderRange from "components/screener/shared/ConnectedSliderRange";
import { FormattedMessage, useIntl } from "react-intl";

export default function TagAlong() {
  const intl = useIntl();

  return (
    <div className="space-y-4">
      <div className="space-y-1">
        <h3 className="font-aeonik text-base font-bold uppercase tracking-wide text-zinc-500">
          <FormattedMessage defaultMessage="Tag Along" id="vO8UwJ" />
        </h3>
        <span className="flex font-aeonik text-sm font-normal italic tracking-wider text-slate-400">
          <FormattedMessage defaultMessage="Selecione uma faixa de Tag Along." id="KSsYsg" />
        </span>
      </div>

      <div className="space-y-9">
        <ConnectedSliderRange
          attribute="tagAlong.commonStock"
          label={intl.formatMessage({
            defaultMessage: "Ações ON",
            id: "ep98Aj",
          })}
          accessibilityLabels={[
            intl.formatMessage({
              defaultMessage: "Tag Along mínimo das Ações Ordinárias",
              id: "fE4TSN",
            }),
            intl.formatMessage({
              defaultMessage: "Tag Along máximo das Ações Ordinárias",
              id: "Em/+ij",
            }),
          ]}
        />

        <ConnectedSliderRange
          attribute="tagAlong.preferredStock"
          label={intl.formatMessage({
            defaultMessage: "Ações PN",
            id: "QDAcUP",
          })}
          accessibilityLabels={[
            intl.formatMessage({
              defaultMessage: "Tag Along mínimo das Ações Preferenciais",
              id: "b6wl8y",
            }),
            intl.formatMessage({
              defaultMessage: "Tag Along máximo das Ações Preferenciais",
              id: "DWs1eF",
            }),
          ]}
        />
      </div>
    </div>
  );
}
