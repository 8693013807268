import type { TBookmarkedIssuer } from "components/bookmarks/ToggleBookmarkButton";
import CompanyActionsDropdown from "components/company/v2/CompanyActionsDropdown";
import { SimpleTextTooltip } from "components/ui/Tooltip";
import classNames from "components/ui/classNames";
import type {
  IAmericanCompanyIssuerObject,
  IAmericanReitIssuerObject,
  IBrazilianCompanyIssuerObject,
  IBrazilianRealEstateFundIssuerObject,
} from "lib/algolia/types/assets";
import useSelfie from "lib/hooks/useSelfie";
import { StarIcon } from "lucide-react";
import Link from "next/link";
import { invariant } from "outvariant";
import { ISecurityCountry } from "types/graphqlTypes";

interface CompanyCardProps {
  company:
    | IAmericanCompanyIssuerObject
    | IAmericanReitIssuerObject
    | IBrazilianCompanyIssuerObject
    | IBrazilianRealEstateFundIssuerObject;
}

function getLinkAs(company: CompanyCardProps["company"]) {
  if (company.type === "AMERICAN_COMPANY" || company.type === "AMERICAN_REIT") {
    return `/us/${company.tickerSymbolPrefix.toLocaleLowerCase()}`;
  }
  if (company.type === "BRAZILIAN_COMPANY") {
    return `/br/${
      company.tickerSymbols.length === 0 ? company.tickerSymbolPrefix : company.tickerSymbols[0].toLowerCase()
    }`;
  }
  invariant(company.tickerSymbols.length > 0, "Missing `tickerSymbols` for: %s", company.name);
  return `/fiis/${company.tickerSymbols[0].toLowerCase()}`;
}

function getLinkHref(company: CompanyCardProps["company"]) {
  if (company.type === "AMERICAN_COMPANY" || company.type === "AMERICAN_REIT") {
    return `/us/[tickerSymbol]`;
  }
  if (company.type === "BRAZILIAN_COMPANY") {
    return `/br/[tickerSymbol]`;
  }
  invariant(company.tickerSymbols.length > 0, "Missing `tickerSymbols` for: %s", company.name);
  return `/fiis/[tickerSymbol]`;
}

export default function CompanyCard({ company }: CompanyCardProps) {
  const user = useSelfie();
  const linkAs = getLinkAs(company);
  const linkHref = getLinkHref(company);

  return (
    <Link
      href={linkHref}
      as={linkAs}
      className={classNames(
        "group flex h-full flex-col rounded-xl p-4 outline-none",
        "box-border select-none bg-white no-underline dark:bg-slate-900",
        "hover:border-violet-200 dark:hover:border-slate-700",
        "focus:border-sky-500",
        "border border-slate-200 shadow-sm dark:border-slate-950",
        "transition-all",
      )}
    >
      <div
        className="grid justify-between gap-x-3"
        style={{
          gridTemplateColumns: company.logoUrl ? "fit-content(100%) max-content" : "fit-content(100%)",
        }}
      >
        <div className="flex flex-col space-y-1 overflow-hidden">
          <h3
            className={classNames(
              "overflow-hidden text-ellipsis whitespace-nowrap text-base font-bold text-slate-600",
              "dark:text-slate-300",
              "group-hover:text-violet-600 dark:group-hover:text-orange-400",
            )}
          >
            {company.name}
          </h3>

          {company.type === "BRAZILIAN_REAL_ESTATE_FUND" && (
            <span className="truncate whitespace-nowrap text-sm font-medium italic text-slate-500 dark:text-slate-400">
              {company.administrator}
            </span>
          )}

          {(company.type === "AMERICAN_COMPANY" || company.type === "BRAZILIAN_COMPANY") && (
            <span className="truncate whitespace-nowrap text-sm font-medium italic text-slate-500 dark:text-slate-400">
              {company.industrySector}
            </span>
          )}

          {company.type === "AMERICAN_REIT" && (
            <span className="truncate whitespace-nowrap text-sm font-medium italic text-slate-500 dark:text-slate-400">
              {company.nareitClassification
                ? company.nareitClassification.propertySectorAndSubsector
                : company.industrySector}
            </span>
          )}
        </div>

        {company.logoUrl && (
          <img
            role="presentation"
            src={company.logoUrl}
            onContextMenu={(event) => event.preventDefault()}
            onMouseDown={(event) => event.preventDefault()}
            width={63}
            height={19}
            alt={company.name}
            draggable={false}
            className="h-[19px] select-none object-contain dark:invert-50"
            style={{
              objectPosition: "top right",
            }}
          />
        )}
      </div>
      {company.description && (
        <div className="mt-2">
          <div className="line-clamp-3">
            <p className="text-sm font-normal tracking-wider text-slate-500 dark:text-neutral-400">
              {company.description}
            </p>
          </div>
        </div>
      )}
      <div className="mt-auto">
        <div className="mt-3">
          <div className="flex w-full items-center justify-between">
            <div
              className={classNames(
                "rounded-md px-2 py-1 text-xs font-bold",
                "bg-violet-50 text-violet-600",
                "dark:bg-orange-300/30 dark:text-orange-100",
              )}
            >
              {company.tickerSymbolPrefix}
            </div>

            <div className="flex items-center space-x-3">
              {user &&
                user.isPremium &&
                !user.isTrialing &&
                company.ratingSummary &&
                company.ratingSummary.overallRating > 0 && (
                  <SimpleTextTooltip
                    content={
                      company.ratingSummary.totalRatings === 1
                        ? `${company.ratingSummary.totalRatings} avaliação`
                        : `${company.ratingSummary.totalRatings} avaliações`
                    }
                  >
                    <div className="flex items-center text-sm">
                      <StarIcon
                        className={classNames(
                          "-mt-[1px] fill-orange-500 text-orange-500",
                          "dark:fill-orange-500 dark:stroke-orange-500 dark:text-orange-500",
                          "size-3.5",
                        )}
                      />

                      <div className="ml-1">
                        <strong className="font-bold text-amber-600 dark:text-orange-500">
                          {company.ratingSummary?.overallRating}
                        </strong>
                      </div>
                    </div>
                  </SimpleTextTooltip>
                )}

              {user && (
                <CompanyActionsDropdown
                  issuerId={company._id}
                  tickerSymbol={company.tickerSymbols[0]}
                  securityCountry={
                    company.type === "AMERICAN_COMPANY" || company.type === "AMERICAN_REIT"
                      ? ISecurityCountry.Us
                      : ISecurityCountry.Br
                  }
                  viewerDidBookmark={company.viewerDidBookmark}
                  isInPortfolio={company.isInPortfolio}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
}

export function createBookmarkedIssuer(
  issuer:
    | IAmericanCompanyIssuerObject
    | IAmericanReitIssuerObject
    | IBrazilianCompanyIssuerObject
    | IBrazilianRealEstateFundIssuerObject,
): TBookmarkedIssuer {
  if (issuer.type === "AMERICAN_COMPANY" || issuer.type === "AMERICAN_REIT") {
    return {
      _id: issuer._id,
      name: issuer.name,
      primaryTickerSymbol: issuer.tickerSymbolPrefix,
      isREIT: issuer.type === "AMERICAN_REIT",
      __typename: "AmericanCompanyIssuer",
    };
  }

  if (issuer.type === "BRAZILIAN_COMPANY") {
    return {
      _id: issuer._id,
      name: issuer.name,
      securities: [
        {
          __typename: "BrazilianSecurity",
          tickerSymbol: issuer.tickerSymbolPrefix,
        },
      ],
      __typename: "BrazilianCompanyIssuer",
    };
  }

  return {
    _id: issuer._id,
    name: issuer.name,
    tickerSymbol: issuer.tickerSymbolPrefix,
    __typename: "BrazilianRealEstateFund",
  };
}
