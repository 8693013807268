import numeral from "numeral";

export default function formatMoneyValue(
  value: number,
  display: "short" | "long" = "long",
  locale: "pt-BR" | "en-US" = "pt-BR",
) {
  try {
    const formatter = new Intl.NumberFormat(locale, {
      // @ts-ignore
      notation: "compact",
      compactDisplay: display,
      minimumFractionDigits: 0,
      maximumFractionDigits: 1,
    });

    return formatter.format(value).replace(/mil$/, "k").replace(/bi$/, "b").replace(/mi$/, "m");
  } catch (err) {
    return numeral(value).format("0a");
  }
}
