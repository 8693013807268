import ConnectedMoneySliderMarksRange from "components/screener/shared/ConnectedMoneySliderMarksRange";
import { FormattedMessage, useIntl } from "react-intl";

export default function MarketCapitalization() {
  const intl = useIntl();

  return (
    <div className="space-y-4 pb-7">
      <div className="space-y-1">
        <h3 className="font-aeonik text-base font-bold uppercase tracking-wide text-zinc-500">
          <FormattedMessage defaultMessage="Market Cap" id="9Mp3OY" />
        </h3>
        <span className="flex font-aeonik text-sm font-normal italic tracking-wider text-slate-400">
          <FormattedMessage defaultMessage="Filtre pelo Valor de Mercado." id="pyuzfH" />
        </span>
      </div>

      <ConnectedMoneySliderMarksRange
        attribute="marketCapitalization"
        label={intl.formatMessage({
          defaultMessage: "Market Cap",
          id: "9Mp3OY",
        })}
        accessibilityLabels={[
          intl.formatMessage({
            defaultMessage: "Market Cap mínimo",
            id: "Q5pJER",
          }),
          intl.formatMessage({
            defaultMessage: "Market Cap máximo",
            id: "0L0LLQ",
          }),
        ]}
      />
    </div>
  );
}
