type TFundamenteiWithTextSizingProps = {
  FULL_WIDTH: React.SVGProps<any>;
  HALF_WIDTH: React.SVGProps<any>;
  MEDIUM: React.SVGProps<any>;
  SMALL: React.SVGProps<any>;
  VERY_SMALL: React.SVGProps<any>;
};

const FundamenteiWithTextSizingProps: TFundamenteiWithTextSizingProps = {
  FULL_WIDTH: {
    width: 716,
    height: 124,
  },
  HALF_WIDTH: {
    width: 358,
    height: 62,
  },
  MEDIUM: {
    width: 260,
    height: 45,
  },
  SMALL: {
    width: 200,
    height: 35,
  },
  VERY_SMALL: {
    width: 180,
    height: 31,
  },
};

type TLogoSizingProps = {
  FundamenteiWithText: typeof FundamenteiWithTextSizingProps;
};

const LogoSizingProps: TLogoSizingProps = {
  FundamenteiWithText: FundamenteiWithTextSizingProps,
};

export default LogoSizingProps;
