import Divider from "components/screener/Divider";
import ResultsCount from "components/screener/ResultsCount";
import ScreenerHits from "components/screener/ScreenerHits";
import {
  AssetType,
  ClearAllFilters,
  FreeFloat,
  GovernmentOwnership,
  IndustrySector,
  ListingSegment,
  NAREITClassificationSector,
  NetMargin,
  RealEstateFundAdministrator,
  RealEstateFundAudience,
  RealEstateFundClassification,
  RealEstateFundManagement,
  RealEstateFundManager,
  RealEstateFundMandate,
  RealEstateFundOperatingSegment,
  TagAlong,
} from "components/screener/refinements";
import Country from "components/screener/refinements/Country";
import CurrentRefinements from "components/screener/refinements/CurrentRefinements";
import IsAmericanDepositaryReceipt from "components/screener/refinements/IsAmericanDepositaryReceipt";
import IsBrazilianDepositaryReceipt from "components/screener/refinements/IsBrazilianDepositaryReceipt";
import IsFiagro from "components/screener/refinements/IsFiagro";
import MarketCapitalization from "components/screener/refinements/MarketCapitalization";
import classNames from "components/ui/classNames";
import type { AppRouterInstance } from "next/dist/shared/lib/app-router-context";
import type { ReadonlyURLSearchParams } from "next/navigation";
import { useState } from "react";
import type { Refinement } from "react-instantsearch-core";
import { InstantSearch } from "react-instantsearch-core";
import type { InstantSearchProps } from "react-instantsearch-dom";
import { Configure } from "react-instantsearch-dom";
import { FormattedMessage } from "react-intl";
import { Value } from "react-powerplug";

interface IScreenerProps extends InstantSearchProps {
  indexName: string;
  router?: AppRouterInstance;
  searchParams?: ReadonlyURLSearchParams;
}

function Screener({ indexName, searchClient, searchState, router, searchParams, ...props }: IScreenerProps) {
  const [isShowingFilters, setIsShowingFilters] = useState(false);
  return (
    <InstantSearch indexName={indexName} searchClient={searchClient} searchState={searchState} {...props}>
      <CurrentRefinements>
        {({ items }) => {
          const filterByAssetType = getFilterByAssetType(searchState, items);
          const isChoosingSectors = hasAppliedRefinement(searchState, items, "industrySector");
          const isChoosingCountry = hasAppliedRefinement(searchState, items, "headquarters.countryName");

          return (
            <Value initial={isChoosingSectors}>
              {({ value: isChoosingSectors, set: setChoosingSectors }) => (
                <Value initial={isChoosingCountry}>
                  {({ value: isChoosingCountry, set: setChoosingCountry }) => (
                    <div className="mx-auto my-0 grid max-w-7xl grid-cols-1 gap-7 px-6 py-6 lg:grid-cols-12 lg:px-0 lg:py-10">
                      <div className="col-span-12 box-border w-full lg:col-span-3">
                        <div className="space-y-6">
                          <Configure maxValuesPerFacet={20} hitsPerPage={3 * 8} />

                          <div className="flex flex-col space-y-4 lg:flex-row lg:justify-between lg:space-y-0">
                            <ResultsCount />
                            {filterByAssetType && (
                              <div className="hidden lg:block">
                                <ClearAllFilters
                                  onClick={() => {
                                    if (router && searchParams && searchParams.has("searchState")) {
                                      router.replace("/");
                                    }
                                  }}
                                />
                              </div>
                            )}
                          </div>

                          <div className="relative space-y-6">
                            <div
                              className={classNames({
                                "grid grid-cols-2 items-end gap-6 md:contents": items.length > 0,
                              })}
                            >
                              <AssetType />

                              <button
                                type="button"
                                className={classNames(
                                  "bg-purple-50 font-aeonik text-xs font-bold uppercase tracking-wider text-purple-700",
                                  "hover:bg-purple-500 hover:text-white",
                                  "h-8 w-full rounded-md border border-purple-900/20",
                                  "lg:hidden lg:w-auto lg:rounded-full lg:border-transparent lg:px-3 lg:py-1",
                                  "focus:outline-none focus:ring-2 focus:ring-purple-200 focus:ring-offset-0",
                                  classNames({
                                    hidden: items.length === 0,
                                    "md:hidden": items.length === 0,
                                  }),
                                )}
                                onClick={() => setIsShowingFilters(!isShowingFilters)}
                              >
                                {isShowingFilters ? (
                                  <FormattedMessage defaultMessage="Ocultar filtros" id="GNZZYR" />
                                ) : (
                                  <FormattedMessage defaultMessage="Ver filtros" id="q/92/k" />
                                )}
                              </button>
                            </div>

                            {filterByAssetType && items.length > 1 && (
                              <div className="flex lg:hidden">
                                <ClearAllFilters
                                  onClick={() => {
                                    if (router && searchParams && searchParams.has("searchState")) {
                                      router.replace("/");
                                    }
                                  }}
                                />
                              </div>
                            )}

                            {filterByAssetType && <Divider />}

                            <div
                              className={classNames(
                                "md:block",
                                classNames({
                                  hidden: !isShowingFilters || items.length === 0,
                                  "md:hidden": !isShowingFilters || items.length === 0,
                                }),
                                "space-y-6 rounded-md border border-slate-600/20 bg-slate-50 p-6",
                                "border-slate-200",
                                "lg:block lg:border-transparent lg:bg-transparent lg:p-0 lg:shadow-none",
                              )}
                            >
                              {filterByAssetType === "BRAZILIAN_REAL_ESTATE_FUND" && (
                                <>
                                  <IsFiagro />
                                  <Divider />
                                  <RealEstateFundAudience />
                                  <RealEstateFundManagement />
                                  <RealEstateFundMandate />
                                  <RealEstateFundOperatingSegment />
                                  <Divider />
                                  <MarketCapitalization />
                                  <Divider />
                                  <RealEstateFundClassification />
                                  <Divider />
                                  <RealEstateFundAdministrator />
                                  <Divider />
                                  <RealEstateFundManager />
                                </>
                              )}

                              {(filterByAssetType === "AMERICAN_COMPANY" || filterByAssetType === "AMERICAN_REIT") && (
                                <>
                                  <IsAmericanDepositaryReceipt />
                                  <Divider />

                                  {filterByAssetType === "AMERICAN_COMPANY" && (
                                    <div
                                      className={classNames({
                                        hidden: isChoosingCountry,
                                      })}
                                    >
                                      <IndustrySector
                                        isChoosingSectors={isChoosingSectors}
                                        onToggleChooseSectors={setChoosingSectors}
                                      />
                                    </div>
                                  )}

                                  {filterByAssetType === "AMERICAN_REIT" && (
                                    <div
                                      className={classNames("space-y-4", {
                                        hidden: isChoosingCountry,
                                      })}
                                    >
                                      <NAREITClassificationSector
                                        isChoosingSectors={true}
                                        attribute="investmentSector"
                                      />

                                      <Divider />

                                      <NAREITClassificationSector
                                        attribute="propertySectorAndSubsector"
                                        isChoosingSectors={isChoosingSectors}
                                        onToggleChooseSectors={setChoosingSectors}
                                      />
                                    </div>
                                  )}

                                  {!isChoosingSectors && !isChoosingCountry && <Divider />}

                                  <div
                                    className={classNames({
                                      hidden: isChoosingSectors,
                                    })}
                                  >
                                    <Country
                                      isChoosingCountry={isChoosingCountry}
                                      onToggleChooseCountry={setChoosingCountry}
                                    />
                                  </div>

                                  <div
                                    className={classNames("space-y-6", {
                                      hidden: isChoosingSectors || isChoosingCountry,
                                    })}
                                  >
                                    <Divider />
                                    <MarketCapitalization />

                                    <Divider />
                                    <NetMargin />
                                  </div>
                                </>
                              )}

                              {filterByAssetType === "BRAZILIAN_COMPANY" && (
                                <>
                                  <IsBrazilianDepositaryReceipt />
                                  <Divider />

                                  <IndustrySector
                                    isChoosingSectors={isChoosingSectors}
                                    onToggleChooseSectors={setChoosingSectors}
                                  />

                                  <div
                                    className={classNames("space-y-6", {
                                      hidden: isChoosingSectors,
                                    })}
                                  >
                                    <Divider />
                                    <NetMargin />
                                    <Divider />
                                    <ListingSegment />
                                    <Divider />
                                    <MarketCapitalization />
                                    <Divider />
                                    <FreeFloat />
                                    <Divider />
                                    <TagAlong />
                                    <Divider />
                                    <GovernmentOwnership />
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-span-12 box-border w-full lg:col-span-9">
                        <ScreenerHits />
                      </div>
                    </div>
                  )}
                </Value>
              )}
            </Value>
          );
        }}
      </CurrentRefinements>
    </InstantSearch>
  );
}

export default Screener;

function hasAppliedRefinement(searchState: Record<string, any>, refinements: Refinement[], forAttribute: string) {
  return (
    refinements.some(({ attribute }) => attribute === forAttribute) ||
    (searchState &&
      searchState.refinementList &&
      searchState.refinementList[forAttribute] &&
      Array.isArray(searchState.refinementList[forAttribute]) &&
      searchState.refinementList[forAttribute].length > 0)
  );
}

function getFilterByAssetType(searchState: Record<string, any>, refinements: Refinement[]): string | null {
  const match = refinements.find(({ attribute }) => attribute === "type");
  let filterByAssetType: string | null = null;

  if (match) {
    filterByAssetType = Array.isArray(match.currentRefinement) ? match.currentRefinement[0] : match.currentRefinement;
  }

  if (filterByAssetType === null && searchState && searchState.menu && searchState.menu.type) {
    filterByAssetType = searchState.menu.type;
  }

  return filterByAssetType;
}
