import isWithinInterval from "date-fns/isWithinInterval";
/* eslint-disable react/no-unstable-nested-components */
import WorkshopCaixaPretaFundamentei from "components/advertisements/WorkshopCaixaPretaFundamentei";
import TwoPassRender from "components/shared/TwoPassRender";
import uniq from "lodash/fp/uniq";
import type { ReactNode } from "react";
import { useState } from "react";

interface CaixaPretaPopUp {
  key: string;
  title: ReactNode;
  popUpContent: ReactNode;
  externalLinkText: string;
  externalLinkHref: string;
  rootClassName?: string;
  period: [Date, Date];
}

interface CaixaPretaPopUpsProps {
  debug?: boolean;
  currentDate: Date;
  popUps: CaixaPretaPopUp[];
}

const CAIXA_PRETA_POPUPS_KEY = "@fundamentei/caixaPretaPopUps";
export default function CaixaPretaPopUps({ debug = false, currentDate, popUps }: CaixaPretaPopUpsProps) {
  const [closedPopUps, setClosedPopUps] = useState<string[]>(() => {
    if (typeof localStorage !== "undefined") {
      const caixaPretaPopUps = localStorage.getItem(CAIXA_PRETA_POPUPS_KEY);
      if (!caixaPretaPopUps) {
        return [];
      }
      try {
        return JSON.parse(caixaPretaPopUps) || [];
      } catch {
        return [];
      }
    }
    return [];
  });

  if (debug) {
    return (
      <TwoPassRender>
        {() => {
          return (
            <div className="m-auto mb-0 mt-8 max-w-7xl space-y-8">
              {popUps.map((popUp) => {
                return (
                  <WorkshopCaixaPretaFundamentei
                    popUpTitle={popUp.title}
                    className={popUp.rootClassName}
                    externalLinkText={popUp.externalLinkText}
                    externalLinkHref={popUp.externalLinkHref}
                    onClose={() => {
                      setClosedPopUps((closedPopUps) => {
                        const nextClosedPopUps = uniq(closedPopUps.concat(popUp.key));
                        localStorage.setItem(CAIXA_PRETA_POPUPS_KEY, JSON.stringify(nextClosedPopUps));
                        return nextClosedPopUps;
                      });
                    }}
                  >
                    {popUp.popUpContent}
                  </WorkshopCaixaPretaFundamentei>
                );
              })}
            </div>
          );
        }}
      </TwoPassRender>
    );
  }

  const popUp = popUps.find(({ period }) => {
    const [start, end] = period;
    return isWithinInterval(currentDate, {
      start,
      end,
    });
  });

  if (!popUp) {
    return null;
  }

  const hasClosedPopUp = closedPopUps.includes(popUp.key);
  return (
    <TwoPassRender>
      {() => {
        if (hasClosedPopUp) {
          return null;
        }
        return (
          <div className="m-auto mb-0 mt-8 max-w-7xl space-y-8">
            <WorkshopCaixaPretaFundamentei
              popUpTitle={popUp.title}
              className={popUp.rootClassName}
              externalLinkText={popUp.externalLinkText}
              externalLinkHref={popUp.externalLinkHref}
              onClose={() => {
                setClosedPopUps((closedPopUps) => {
                  const nextClosedPopUps = uniq(closedPopUps.concat(popUp.key));
                  localStorage.setItem(CAIXA_PRETA_POPUPS_KEY, JSON.stringify(nextClosedPopUps));
                  return nextClosedPopUps;
                });
              }}
            >
              {popUp.popUpContent}
            </WorkshopCaixaPretaFundamentei>
          </div>
        );
      }}
    </TwoPassRender>
  );
}
