"use client";

import * as React from "react";
import * as TabsPrimitive from "@radix-ui/react-tabs";
import classNames from "components/ui/classNames";

const Tabs = TabsPrimitive.Root;

const TabsList = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.List>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.List>
>(({ className, ...props }, ref) => (
  <TabsPrimitive.List
    ref={ref}
    className={classNames(
      "inline-flex items-center justify-center rounded-xl bg-slate-100 p-1 dark:bg-slate-500/10",
      className,
    )}
    {...props}
  />
));

TabsList.displayName = TabsPrimitive.List.displayName;

const TabsTrigger = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.Trigger>
>(({ className, ...props }, ref) => (
  <TabsPrimitive.Trigger
    className={classNames(
      "inline-flex min-w-[100px] items-center justify-center px-3 py-1.5",
      "rounded-lg text-sm font-medium text-slate-700",
      "disabled:pointer-events-none disabled:opacity-50",
      "data-[state=active]:bg-white data-[state=active]:text-slate-900 data-[state=active]:shadow-sm",
      "dark:text-slate-200 dark:data-[state=active]:bg-slate-950 dark:data-[state=active]:text-slate-100",
      className,
    )}
    {...props}
    ref={ref}
  />
));

TabsTrigger.displayName = TabsPrimitive.Trigger.displayName;

const TabsContent = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.Content>
>(({ className, ...props }, ref) => (
  <TabsPrimitive.Content
    className={classNames("mt-2 rounded-md border border-slate-200 p-6 dark:border-slate-700", className)}
    {...props}
    ref={ref}
  />
));

TabsContent.displayName = TabsPrimitive.Content.displayName;

export { Tabs, TabsList, TabsTrigger, TabsContent };
