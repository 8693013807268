import { gql } from "@apollo/client";
import AssetPortfolioPositionFields from "components/portfolio/fragments/AssetPortfolioPositionFields";

export default gql`
  mutation AddPositionToPortfolio($input: AddPositionToPortfolioInput!) {
    addPositionToPortfolio(input: $input) {
      ...AssetPortfolioPositionFields
    }
  }

  ${AssetPortfolioPositionFields}
`;
