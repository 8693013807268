import getBaseUrl from "lib/utils/getBaseUrl";
import {
  AreaChartIcon,
  BarChart3Icon,
  BriefcaseBusinessIcon,
  CalendarIcon,
  DollarSignIcon,
  Grid2x2CheckIcon,
  HelpCircleIcon,
  MegaphoneIcon,
  MessageCircleIcon,
  PanelsTopLeftIcon,
  ShieldAlertIcon,
  WalletIcon,
} from "lucide-react";
import type { IntlShape } from "react-intl";

export default function getPlatformFeatures(intl: IntlShape, isUsLanguage: boolean) {
  return {
    secondSection: [
      {
        icon: <PanelsTopLeftIcon className="inline-flex stroke-current" size={24} strokeWidth={1.5} />,
        feature: intl.formatMessage({ defaultMessage: "Monte a sua Tabela", id: "Bik44G" }),
        description: intl.formatMessage({
          defaultMessage: "Crie templates para análise com os seus indicadores favoritos!",
          id: "g94Pk8",
        }),
        videoUrl: isUsLanguage
          ? `${getBaseUrl()}/static/landing/video/en/bring-your-own-table.mp4`
          : `${getBaseUrl()}/static/landing/video/pt/bring-your-own-table.mp4`,
      },
      {
        icon: <Grid2x2CheckIcon className="inline-flex stroke-current" size={24} strokeWidth={1.5} />,
        feature: intl.formatMessage({ defaultMessage: "Comparador de Ativos", id: "p9iN+U" }),
        description: intl.formatMessage({
          defaultMessage: "Coloque alguns ativos lado a lado e veja quais deles possuem os melhores dados!",
          id: "sljbfl",
        }),
        videoUrl: isUsLanguage
          ? `${getBaseUrl()}/static/landing/video/en/compare.mp4`
          : `${getBaseUrl()}/static/landing/video/pt/compare.mp4`,
      },
      {
        icon: <ShieldAlertIcon className="inline-flex stroke-current" size={24} strokeWidth={1.5} />,
        feature: intl.formatMessage({ defaultMessage: "KPIs", id: "TXLox5" }),
        description: intl.formatMessage({
          defaultMessage: "Acesse os indicadores-chave de desempenho de 1.800+ Stocks!",
          id: "ktnqyr",
        }),
        videoUrl: isUsLanguage
          ? `${getBaseUrl()}/static/landing/video/en/kpis.mp4`
          : `${getBaseUrl()}/static/landing/video/pt/kpis.mp4`,
      },
    ],
    thirdSection: [
      {
        icon: <MessageCircleIcon className="inline-flex stroke-zinc-400" size={16} strokeWidth={2} />,
        feature: intl.formatMessage({ defaultMessage: "Discussões", id: "aDdimF" }),
        description: intl.formatMessage({
          defaultMessage: "Converse com outros investidores na melhor comunidade de Buy&Hold do Brasil!",
          id: "VrZQ2V",
        }),
      },
      {
        icon: <HelpCircleIcon className="inline-flex stroke-zinc-400" size={16} strokeWidth={2} />,
        feature: intl.formatMessage({ defaultMessage: "Suporte", id: "Di3PGS" }),
        description: intl.formatMessage({
          defaultMessage: "Você será respondido diretamente por um dos Cofundadores: Eduardo ou Andrey.",
          id: "GU3vtX",
        }),
      },
      {
        icon: <CalendarIcon className="inline-flex stroke-zinc-400" size={16} strokeWidth={2} />,
        feature: intl.formatMessage({ defaultMessage: "Agenda de Balanços", id: "CDWP1s" }),
        description: intl.formatMessage({
          defaultMessage: "Veja em um único lugar as datas dos próximos balanços das Ações, Stocks e REITs.",
          id: "p4tkoe",
        }),
      },
      {
        icon: <MegaphoneIcon className="inline-flex stroke-zinc-400" size={16} strokeWidth={2} />,
        feature: intl.formatMessage({ defaultMessage: "Comunicados e Fatos Relevantes", id: "jt6pmD" }),
        description: intl.formatMessage({
          defaultMessage: "Acompanhe os comunicados e fatos relevantes das empresas que investe.",
          id: "Eh54OF",
        }),
      },
      {
        icon: <BarChart3Icon className="inline-flex stroke-zinc-400" size={16} strokeWidth={2} />,
        feature: intl.formatMessage({ defaultMessage: "Liquidez", id: "onsaKu" }),
        description: intl.formatMessage({
          defaultMessage: "Número de negócios diários, volume financeiro e quantidade de ações negociadas.",
          id: "vVyVUh",
        }),
      },
      {
        icon: <DollarSignIcon className="inline-flex stroke-zinc-400" size={16} strokeWidth={2} />,
        feature: intl.formatMessage({ defaultMessage: "Remuneração dos Executivos", id: "h64uqI" }),
        description: intl.formatMessage({
          defaultMessage: "A empresa é boa para seus acionistas ou apenas para seus executivos?",
          id: "90VC1t",
        }),
      },
      {
        icon: <AreaChartIcon className="inline-flex stroke-zinc-400" size={16} strokeWidth={2} />,
        feature: intl.formatMessage({ defaultMessage: "Ações em Circulação", id: "psT3CN" }),
        description: intl.formatMessage({
          defaultMessage: "A empresa está emitindo muitas ações e diluindo seus acionistas?",
          id: "311Gzm",
        }),
      },
      {
        icon: <BriefcaseBusinessIcon className="inline-flex stroke-zinc-400" size={16} strokeWidth={2} />,
        feature: intl.formatMessage({ defaultMessage: "Receita por Empregado", id: "Wn7Kp4" }),
        description: intl.formatMessage({
          defaultMessage: "Quem é mais eficiente em um setor com mais receita por empregado?",
          id: "rfq0dP",
        }),
      },
      {
        icon: <WalletIcon className="inline-flex stroke-zinc-400" size={16} strokeWidth={2} />,
        feature: intl.formatMessage({ defaultMessage: "Gerenciador de Ativos", id: "Ol+Tls" }),
        description: intl.formatMessage({
          defaultMessage: "Defina um % para cada ativo na sua carteira e decida em qual investir no momento.",
          id: "l3h86f",
        }),
      },
    ],
  };
}
