import { RangeSlider } from "lib/miolo";
import { useState } from "react";
import { connectRange } from "react-instantsearch-dom";
import * as palette from "../../palette";

type TRefineArguments = {
  min?: number;
  max?: number;
};

interface IConnectedSliderRangeProps {
  label: string;
  accessibilityLabels: [string, string];
  refine: (refinement: TRefineArguments) => void;
  currentRefinement: {
    min?: number;
    max?: number;
  };
}

function ConnectedSliderRange({ label, accessibilityLabels, refine, currentRefinement }: IConnectedSliderRangeProps) {
  const [willUpdateToValues, setWillUpdateToValues] = useState<ReadonlyArray<number> | null>(null);

  return (
    <div className="grid gap-y-5">
      <div className="flex justify-between text-sm tracking-wider text-brand dark:text-slate-300">
        <strong className="font-bold">{label}</strong>

        {willUpdateToValues ? (
          <strong
            className="font-bold"
            style={{
              color: palette.baliHai,
            }}
          >
            {willUpdateToValues[0] || 0}% — {willUpdateToValues[1] || 100}%
          </strong>
        ) : (
          <strong className="font-bold">
            {currentRefinement.min || 0}% — {currentRefinement.max || 100}%
          </strong>
        )}
      </div>

      <RangeSlider
        domain={[0, 100]}
        values={[currentRefinement.min || 0, currentRefinement.max || 100]}
        labels={accessibilityLabels}
        onUpdate={setWillUpdateToValues}
        onChange={([min, max]) => {
          setWillUpdateToValues(null);

          if (min === 0 && max === 100) {
            refine({
              min: undefined,
              max: undefined,
            });
          } else {
            refine({
              min,
              max,
            });
          }
        }}
      />
    </div>
  );
}

export default connectRange(ConnectedSliderRange);
