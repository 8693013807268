import { gql } from "@apollo/client";

export default gql`
  fragment AssetPortfolioPositionFields on AssetPortfolioPosition {
    _id
    tickerSymbol
    numberOfShares
    price {
      close
      date
    }
    goal
    security {
      _id
      type
      tickerSymbol
      name

      ... on BrazilianSecurity {
        category
      }

      issuer {
        ... on AmericanCompanyIssuer {
          _id
          name
          tickerSymbols
          sector
          isREIT
        }
        ... on BrazilianCompanyIssuer {
          _id
          name
          tickerSymbolPrefix
          sector
          cnpj
          bookkeeper
        }
        ... on BrazilianRealEstateFundIssuer {
          _id
          name
          tickerSymbolPrefix
          sector
          cnpj
        }
      }
    }
  }
`;
