import { NativeSelect } from "lib/miolo";
import type { MenuProvided } from "react-instantsearch-core";
import { connectMenu } from "react-instantsearch-dom";
import { FormattedMessage } from "react-intl";

const RealEstateFundAudienceMenu = connectMenu<MenuProvided>(({ refine, currentRefinement }) => {
  return (
    <div className="space-y-4">
      <h3 className="font-aeonik text-base font-bold uppercase tracking-wide text-zinc-500">
        <FormattedMessage defaultMessage="Publico-alvo" id="eU0V4m" />
      </h3>

      <NativeSelect value={currentRefinement || ""} onChange={(event) => refine(event.currentTarget.value || "")}>
        <option value="">
          <FormattedMessage defaultMessage="Todos" id="P8VfZA" />
        </option>
        <option value="Investidor Profissional">
          <FormattedMessage defaultMessage="Investidor Profissional" id="Ew/GX+" />
        </option>
        <option value="Investidor Qualificado">
          <FormattedMessage defaultMessage="Investidor Qualificado" id="Wi7hUc" />
        </option>
        <option value="Investidor Qualificado e Profissional">
          <FormattedMessage defaultMessage="Investidor Qualificado e Profissional" id="crNbgy" />
        </option>
        <option value="Investidores em Geral">
          <FormattedMessage defaultMessage="Investidores em Geral" id="or0mzL" />
        </option>
      </NativeSelect>
    </div>
  );
});

export default function RealEstateFundAudience() {
  return <RealEstateFundAudienceMenu attribute="audience" />;
}
