import { NativeSelect } from "lib/miolo";
import type { MenuProvided } from "react-instantsearch-core";
import { connectMenu } from "react-instantsearch-dom";
import { FormattedMessage } from "react-intl";

const RealEstateFundMandateMenu = connectMenu<MenuProvided>(({ refine, currentRefinement }) => {
  return (
    <div className="space-y-4">
      <h3 className="font-aeonik text-base font-bold uppercase tracking-wide text-zinc-500">
        <FormattedMessage defaultMessage="Mandato" id="kBgOJ9" />
      </h3>

      <NativeSelect value={currentRefinement || ""} onChange={(event) => refine(event.currentTarget.value || "")}>
        <option value="">
          <FormattedMessage defaultMessage="Todos" id="P8VfZA" />
        </option>
        <option value="Híbrido">
          <FormattedMessage defaultMessage="Híbrido" id="tSFx0p" />
        </option>
        <option value="Renda">
          <FormattedMessage defaultMessage="Renda" id="/tdg1X" />
        </option>
        <option value="Desenvolvimento para Renda">
          <FormattedMessage defaultMessage="Desenvolvimento para Renda" id="5bC9Vj" />
        </option>
        <option value="Desenvolvimento para Venda">
          <FormattedMessage defaultMessage="Desenvolvimento para Venda" id="PPi3i7" />
        </option>
        <option value="Títulos e Valores Mobiliários">
          <FormattedMessage defaultMessage="Títulos e Valores Mobiliários" id="Wjpilv" />
        </option>
      </NativeSelect>
    </div>
  );
});

export default function RealEstateFundMandate() {
  return <RealEstateFundMandateMenu attribute="mandate" />;
}
