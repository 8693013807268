export default function StatueOfLiberty(props: React.SVGProps<any>) {
  return (
    <svg width={23} height={35} viewBox="0 0 23 35" {...props}>
      <g fillRule="nonzero" fill="none">
        <g fill="#368A50">
          <path d="M14.342 14.917a.53.53 0 01-.524-.536V9.774a.53.53 0 01.524-.536c.29 0 .524.24.524.536v4.607a.53.53 0 01-.524.536zM16.234 15.454a.513.513 0 01-.27-.077.543.543 0 01-.178-.735l1.956-3.324a.517.517 0 01.719-.183.543.543 0 01.179.736l-1.956 3.324a.522.522 0 01-.45.26zM17.342 16.506a.52.52 0 01-.429-.227.543.543 0 01.127-.746l2.567-1.853a.517.517 0 01.73.13.543.543 0 01-.125.746l-2.568 1.852a.513.513 0 01-.302.098zM17.862 17.604a.538.538 0 01-.149-1.05l2.078-.629a.522.522 0 01.65.362.538.538 0 01-.353.666l-2.077.629a.509.509 0 01-.15.022zM12.45 15.454a.521.521 0 01-.449-.26l-1.956-3.323a.543.543 0 01.179-.736.518.518 0 01.719.183l1.956 3.324c.15.254.07.583-.179.735a.513.513 0 01-.27.077zM11.343 16.506a.514.514 0 01-.302-.098l-2.568-1.852a.543.543 0 01-.126-.747.517.517 0 01.73-.129l2.568 1.853c.237.17.293.505.126.746a.52.52 0 01-.428.227zM10.823 17.604c-.05 0-.1-.007-.149-.022l-2.077-.63a.538.538 0 01-.354-.665.522.522 0 01.651-.361l2.078.629a.538.538 0 01-.149 1.05zM10.675 29.09V18.132h7.335v13.96" />
        </g>
        <path
          fill="#5EBC77"
          d="M20.889 31.026l1.208-1.128-3.354-4.714h-6.811l-3.248-1.482-3.616-12.606H3.444l1.991 16.767-1.572 2.013 2.41 3.022V35h16.662z"
        />
        <g fill="#368A50">
          <path d="M7.157 25.658L3.624 12.607l1.811 15.256" />
          <path d="M8.684 23.702l-3.146 8.274-1.675-2.1zM8.247 22.177l.437 1.525-3.249 4.16-.422-3.557zM5.069 6.292v4.804h-1.82c-.141 0-.278-.018-.41-.052a1.723 1.723 0 01-1.281-1.678V6.292h3.51zM10.113 35l5.45-9.816h3.18L11.685 35z" />
        </g>
        <path
          d="M5.069 8.94v2.156h-1.82c-.141 0-.278-.018-.41-.052V9.948a.998.998 0 01.987-1.009h1.243z"
          fill="#5EBC77"
        />
        <path fill="#FFA700" d="M1.422 5.471l-.6-4.433L2.417 2.31 3.59 0l.758 2.17 1.456-1.132-.507 4.433" />
        <path
          d="M14.342 14.381c-2.025 0-3.667 1.68-3.667 3.75v1.821c0 2.071 1.642 3.75 3.667 3.75 2.026 0 3.668-1.679 3.668-3.75v-1.821c0-2.07-1.642-3.75-3.668-3.75z"
          fill="#5EBC77"
        />
        <g fill="#368A50">
          <path d="M11.932 19.952v-1.821c0-1.852 1.313-3.389 3.04-3.694a3.605 3.605 0 00-.63-.056c-2.025 0-3.667 1.679-3.667 3.75v1.821c0 2.071 1.642 3.75 3.667 3.75.215 0 .425-.02.63-.056-1.727-.305-3.04-1.842-3.04-3.694z" />
          <path d="M18.01 18.131v1.821l-.02.002a3.77 3.77 0 00-1.054-2.26 3.617 3.617 0 00-2.594-1.098c-1.96 0-3.562 1.573-3.662 3.552a3.684 3.684 0 01-.005-.196v-1.82c0-1.036.411-1.974 1.074-2.653a3.617 3.617 0 012.593-1.098c2.026 0 3.668 1.679 3.668 3.75z" />
        </g>
        <path fill="#47A562" d="M.065 5.435h6.497v1.714H.065z" />
        <g fill="#368A50">
          <path d="M.065 5.435h2.783V7.15H.065zM16.455 18.998l-1.993-.478h-.005a.504.504 0 00-.043-.008l-.014-.003a.516.516 0 00-.115 0c-.005 0-.01.002-.014.003a.532.532 0 00-.043.007l-.005.001-1.994.478a.536.536 0 00-.39.643.524.524 0 00.63.4l1.35-.323v.801a.53.53 0 00.524.536.53.53 0 00.523-.536v-.801l1.35.323a.527.527 0 00.63-.4.537.537 0 00-.39-.643zM20.889 31.026l-2.892 2.708 3.379-1.762M11.932 25.184l-2.039 2.978.782-3.551" />
        </g>
      </g>
    </svg>
  );
}
