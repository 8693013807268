import type { IntlShape } from "react-intl";

export default function getPlatformMetrics(intl: IntlShape) {
  return {
    BR: [
      {
        value: 450,
        suffix: "K+",
        indicator: intl.formatMessage({ defaultMessage: "Investidores cadastrados", id: "txdbyx" }),
      },
      {
        value: 6,
        suffix: "K+",
        indicator: intl.formatMessage({ defaultMessage: "Total de ativos disponíveis", id: "s/BmK8" }),
      },
      {
        value: 30,
        suffix: "+",
        indicator: intl.formatMessage({ defaultMessage: "Anos de balanços para Ações", id: "frq/CR" }),
      },
      {
        value: 45,
        suffix: "+",
        indicator: intl.formatMessage({ defaultMessage: "Anos de balanços para Stocks", id: "SiJPlI" }),
      },
    ],
    US: [
      {
        value: 6,
        suffix: "K+",
        indicator: intl.formatMessage({ defaultMessage: "Total de ativos disponíveis", id: "s/BmK8" }),
      },
      {
        value: 1.8,
        decimals: 1,
        separator: ",",
        suffix: "K+",
        indicator: intl.formatMessage({
          defaultMessage: "Empresas com KPIs disponíveis",
          id: "mqGe2X",
        }),
      },
      {
        value: 900,
        suffix: "+",
        indicator: intl.formatMessage({ defaultMessage: "Indicadores fundamentalistas", id: "zHIsY4" }),
      },
      {
        value: 45,
        suffix: "+",
        indicator: intl.formatMessage({ defaultMessage: "Anos de dados disponíveis ", id: "vnquLo" }),
      },
    ],
  };
}
