/* eslint-disable react-hooks/rules-of-hooks */
import { useIntl } from "react-intl";

export default function getHumanReadableLabel(machineReadableLabel: string): string | null {
  const intl = useIntl();

  if (machineReadableLabel === "AMERICAN_COMPANY") {
    return intl.formatMessage({
      defaultMessage: "Stocks",
      id: "6rgkM5",
    });
  }

  if (machineReadableLabel === "AMERICAN_REIT") {
    return intl.formatMessage({
      defaultMessage: "REITs",
      id: "QOIFTQ",
    });
  }

  if (machineReadableLabel === "BRAZILIAN_COMPANY") {
    return intl.formatMessage({
      defaultMessage: "Ações",
      id: "FLGl5o",
    });
  }

  if (machineReadableLabel === "BRAZILIAN_REAL_ESTATE_FUND") {
    return intl.formatMessage({
      defaultMessage: "FIIs",
      id: "f9DMbU",
    });
  }

  return null;
}
