import { useState, useEffect } from "react";
import * as React from "react";
import classNames from "components/ui/classNames";
import { ArrowDownIcon, ArrowUpIcon } from "lucide-react";
import { Separator } from "components/ui/Separator";

enum ScrollDirection {
  Top = "top",
  Bottom = "bottom",
  TopBottom = "topBottom",
}

export default function BackToTop() {
  const [scrollDirection, setScrollDirection] = useState<ScrollDirection>(ScrollDirection.TopBottom);

  const checkScrollTop = () => {
    const offset = window.pageYOffset;
    const screenHeight = window.innerHeight;
    const scrollPercentage = (offset / screenHeight) * 100;

    if (scrollPercentage >= 0 && scrollPercentage <= 5) {
      setScrollDirection(ScrollDirection.Bottom);
    } else if (scrollPercentage >= 95) {
      setScrollDirection(ScrollDirection.Top);
    } else {
      setScrollDirection(ScrollDirection.TopBottom);
    }
  };

  const scrollTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const scrollBottom = () => {
    const windowHeight = window.innerHeight;
    const documentHeight = document.body.scrollHeight;
    const bottomPosition = documentHeight - windowHeight;

    window.scrollTo({
      top: bottomPosition,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", checkScrollTop);

    return () => {
      window.removeEventListener("scroll", checkScrollTop);
    };
  }, [scrollDirection]);

  return (
    <div className="fixed bottom-6 right-6 z-[3] cursor-pointer">
      <div
        className={classNames(
          "m-0 flex h-12 w-12 cursor-pointer select-none appearance-none flex-col overflow-hidden",
          "items-center justify-center rounded-full border-none",
          "bg-black p-0 text-white opacity-40 outline-none",
          "hover:animate-wiggle",
        )}
      >
        <button
          type="button"
          onClick={scrollTop}
          tabIndex={-1}
          className={classNames(
            "flex h-1/2 w-full items-center justify-center",
            {
              "h-full": scrollDirection === ScrollDirection.Top,
              hidden: scrollDirection === ScrollDirection.Bottom,
            },
            "hover:bg-blue-600 hover:opacity-100 hover:shadow-lg hover:transition-shadow",
          )}
        >
          {[ScrollDirection.TopBottom, ScrollDirection.Top].includes(scrollDirection) && <ArrowUpIcon size={20} />}
        </button>
        {scrollDirection === ScrollDirection.TopBottom && <Separator className="bg-white/10" />}
        <button
          type="button"
          onClick={scrollBottom}
          tabIndex={-2}
          className={classNames(
            "flex h-1/2 w-full items-center justify-center",
            {
              "h-full": scrollDirection === ScrollDirection.Bottom,
              hidden: scrollDirection === ScrollDirection.Top,
            },
            "hover:bg-blue-600 hover:opacity-100 hover:shadow-lg hover:transition-shadow",
          )}
        >
          {[ScrollDirection.TopBottom, ScrollDirection.Bottom].includes(scrollDirection) && <ArrowDownIcon size={20} />}
        </button>
      </div>
    </div>
  );
}
